import React, { useEffect, useState } from "react";
import logo from "../images/logo-white.svg";
import check from "../images/check-icon.png";
import upload from "../images/upload-icon.png";
import einspruch from "../images/einspruch.png";
import arrow from "../images/arrow.svg";
import JSOnly from "../images/JSOnly-White.svg";
import openAi from "../images/powered-by-openai-badge-filled-on-dark.svg";
import phone from "../images/phone.png";
import email from "../images/email.png";
import { Link, useNavigate } from "react-router-dom";
import { sendUserData } from "../utils/tracking.js";
import { Turn as Hamburger } from "hamburger-react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { motion } from "framer-motion";
import AwesomeButtonStyles from "./buttonstyles.css";
import WavyBackground from "./WavyBackground.jsx";

function HomePage() {
  useEffect(() => {
    sendUserData("Homepage");
  }, []);

  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className=" pt-4 pb-12 justify-center grid bg-black max-w-[100vw] overflow-y-hidden">
        {/* START mobile menu */}
        <div className="flex flex-row px-4 justify-start gap-6 onlyMobile ">
          <img
            src={logo}
            alt=""
            className="h-[2.2rem] mx-auto flex-grow mt-2"
          />
          <div className="relative z-50">
            <Hamburger
              toggled={isMobileMenuOpen}
              toggle={setIsMobileMenuOpen}
              color="#ffffff"
              size={20}
            />
          </div>
        </div>
        {/* END mobile menu */}

        <WavyBackground>
          <div className="flex  items-center w-[90%] gap-8 max-sm:hidden">
            <div className="flex-grow">
              <img src={logo} alt="" className="h-[2rem] mx-auto" />
            </div>
            <div className="flex-grow"></div>

            <div className=" flex flex-row justify-center gap-8 mx-auto text-[.6rem] max-sm:max-w-[95vw] max-sm:text-[.5rem] mt-4">
              <Link to="/Bussgeldkatalog">
                <p
                  className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
                >
                  Bußgeldkatalog
                </p>
              </Link>
              <Link to="/Impressum">
                <p
                  className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
                >
                  IMPRESSUM
                </p>
              </Link>
              <Link to="/Datenschutz">
                <p
                  className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
                >
                  DATENSCHUTZ
                </p>
              </Link>
              <Link to="/AGB">
                <p
                  className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
                >
                  AGB
                </p>
              </Link>
            </div>
          </div>

          <Link to="/upload" className="">
            <div className="bg-[#00000090] rounded-xl mt-12 p-4 max-sm:bg-transparent max-sm:mt-2">
              <h1 className="mx-auto mt-2 text-white font-output  uppercase text-center text-[1.4rem] max-sm:text-[1.4rem] max-sm:leading-6">
                Überprüfen Sie Ihr Bußgeld in Sekunden
              </h1>
              <p className="text-white font-para text-[.5rem] uppercase tracking-[.4rem] text-center max-sm:text-[.6rem] max-sm:tracking-[.1rem]">
                
              </p>
             
            </div>
          </Link>

          <div className="flex flex-row justify-center gap-6 max-sm:flex-col max-sm:gap-0 max-sm:px-4">
            <div className="flex flex-col px-4 min-w-[13rem] h-fit p-4 mt-4 bg-[#00000090] rounded-xl max-sm:mt-0 ">
              <img
                src={upload}
                alt=""
                className="h-[2.5rem] w-[2.5rem] max-sm:mx-auto"
              />
              <h2 className="text-white font-para font-black  uppercase mb-0 text-[.7rem] mt-2 max-sm:mx-auto">
                Foto Hochladen
              </h2>
              <p className="w-[300px] font-para text-white text-[.5rem] blocksatz max-sm:text-[.7rem]">
                Einfach ein Foto Ihres Bußgeldbescheids machen und hier
                hochladen. Keine Sorge, wir speichern Ihr Dokument und Ihre Daten nicht!{" "}
              </p>
            </div>
            <div className="flex flex-col px-4 min-w-[13rem] h-fit p-4 mt-4 bg-[#00000090] rounded-xl">
              <img
                src={check}
                alt=""
                className="h-[2.5rem] w-[2.5rem] max-sm:mx-auto"
              />
              <h2 className="text-white font-para font-black  uppercase mb-0 text-[.7rem] mt-2 max-sm:mx-auto">
                Prüfung in Echtzeit
              </h2>
              <p className="w-[300px] font-para text-white text-[.5rem] blocksatz max-sm:text-[.7rem]">
                Ihr Bußgeldbescheid wird mit den neusten Vorschriften abgeglichen. 
                Sie erhalten eine Empfehlung, ob sich ein Einspruch lohnt.
              </p>
            </div>
            <div className="flex flex-col px-4 min-w-[13rem] h-fit p-4 mt-4 bg-[#00000090] rounded-xl mb-4">
              <img
                src={einspruch}
                alt=""
                className="h-[2.5rem] w-[2.5rem] max-sm:mx-auto "
              />
              <h2 className="text-white font-para font-black  uppercase mb-0 text-[.7rem] mt-2 max-sm:mx-auto">
                bequem zum Einspruch
              </h2>
              <p className="w-[300px] font-para text-white text-[.5rem] blocksatz max-sm:text-[.7rem]">
                Falls ein Einspruch empohlen wird, erledigen wir das für Sie. Einfach eben online die Vollmacht unterschreiben.
              </p>
            </div>
          </div>
        </WavyBackground>

        <Link to="/upload" className="w-fit mx-auto mt-6">
          <a
            href="#_"
            class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-[#FFFFFF] transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-[#22222290] group"
          >
            <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-[#0096FF] group-hover:h-full"></span>
            <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12 max-sm:pr-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32px"
                viewBox="0 -960 960 960"
                width="32px"
                fill="#0096FF"
              >
                <path d="M80-200v-80h400v80H80Zm0-200v-80h200v80H80Zm0-200v-80h200v80H80Zm744 400L670-354q-24 17-52.5 25.5T560-320q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 29-8.5 57.5T726-410l154 154-56 56ZM560-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z" />
              </svg>
            </span>
            <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
              <svg
                class="w-5 h-5 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                ></path>
              </svg>
            </span>
            <span class="relative w-full text-left font-para text-sm transition-colors duration-200 ease-in-out group-hover:text-white">
              {" "}
              BUSSGELD HOCHLADEN{" "}
            </span>
          </a>
        </Link>

        <div className="grid mx-auto mb-12">
          <p className="text-white font-para tracking-widest uppercase text-center mx-auto text-[15px] mt-4 z-50 ">
            oder zum
          </p>
          <Link to="/Bussgeldkatalog">
            <a
              href="#_"
              class="relative inline-flex items-center px-12 py-3 overflow-hidden text-md font-medium text-white border-0  rounded-full hover:text-white group hover:bg-gray-50 max-w-fit mx-auto mt-3 "
            >
              <span class="absolute left-0 block w-full h-0 transition-all bg-[#0096FF] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
              <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span class="relative font-para">BUSSGELDKATALOG</span>
            </a>
          </Link>
        </div>
        {/* MENU */}
      </div>

      <div className="flex flex-row w-full h-fit pt-[8rem] bg-black justify-center gap-4 max-sm:flex-col-reverse  ">
        <div className="w-[30%] h-fit flex flex-col gap-2 max-sm:hidden pt-[2rem] ">
          <h2 className="text-white font-header font-black text-[2rem]">
            MENU
          </h2>
          <Link to="/upload" className="w-full font-para uppercase">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
            >
              Upload
            </p>
          </Link>
          <Link to="/Bussgeldkatalog">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
            >
              Bußgeldkatalog
            </p>
          </Link>
          <Link to="/Impressum">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
            >
              IMPRESSUM
            </p>
          </Link>
          <Link to="/Datenschutz">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
            >
              DATENSCHUTZ
            </p>
          </Link>
          <Link to="/AGB">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
            >
              AGB
            </p>
          </Link>
        </div>
        <div className="w-[30%] h-[fit] pt-[2rem] max-sm:w-fit max-sm:text-center max-sm:justify-center max-sm:mx-auto">
          <h2 className="text-white font-header font-black text-[2rem] uppercase">
            Hauptsitz
          </h2>
          <p className="font-para text-white   text-[.8rem] max-sm:text-center max-sm:mx-auto">
            Kalteiche-Ring 46<br></br>
            35708 Haiger<br></br>
            Germany<br></br>
            Mo-Do: 09:00 - 17:00<br></br>
            Fr: 09:00 - 15:00<br></br>
            <br></br>
            <span className="flex flex-row gap-2">
              <img src={phone} alt="Phone Icon." className="h-3 mt-1" /> +49
              2736 2009650
            </span>
            <span className="flex flex-row gap-2">
              <img src={email} alt="Phone Icon." className="h-3 mt-1" />{" "}
              service@JuraSolutions.de
            </span>
          </p>
        </div>
        <div className="w-[30%] h-[fit] pt-[3rem]   content-center justify-center  max-sm:w-fit max-sm:mx-auto max-sm:h-fit max-sm:-mt-[2rem]">
          {" "}
          <img
            src={JSOnly}
            alt="JuraSolutions Logo."
            className="h-[8rem] mx-auto"
          />
          <p className="font-para uppercase text-white tracking-widest mx-auto mt-2 text-center text-[10px] font-bold max-sm:text-[.5rem]">
            JuraSolutions Rechtsanwaltsgesellschaft mbH
          </p>
          <img
                src={openAi}
                alt="OpenAi Logo"
                className="h-6 mx-auto mt-4 max-sm:h-8 mb-4"
              />
        </div>
      </div>
      {isMobileMenuOpen && (
        <motion.div
          className="fixed top-0 right-0 h-full bg-black z-10"
          initial={{ x: "100%" }} // Start from the right
          animate={{ x: 0 }} // End at the original position
          exit={{ x: "100%" }} // Exit to the right
          transition={{ type: "tween", duration: 0.3 }} // Customize the transition
        >
          <div className="flex flex-col items-start justify-start p-6 space-y-4 text-white">
            <h2 className="text-white font-header font-black text-[2rem] mt-6">
              MENU
            </h2>
            <Link to="/upload" className="w-full font-para uppercase">
              Upload
            </Link>
            <Link to="/Bussgeldkatalog" className="w-full font-para uppercase">
              Bußgeldkatalog
            </Link>
            <Link to="/Impressum" className="w-full font-para uppercase">
              IMPRESSUM
            </Link>
            <Link to="/Datenschutz" className="w-full font-para uppercase">
              DATENSCHUTZ
            </Link>
            <Link to="/AGB" className="w-full font-para uppercase">
              AGB
            </Link>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default HomePage;
