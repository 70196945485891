import React, { useEffect } from "react";
import logo from "../images/logo.svg";
import arrow from "../images/arrow.svg";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {sendUserData} from "../utils/tracking.js"


function Sorry() {
  useEffect(() => {
    sendUserData('Einspruch lohnt nicht'); 
  }, []);
  return (
    <div className="px-12 max-sm:max-w-[95vw] py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12">
      {" "}
      <img src={logo} alt="" className="h-[5rem] mx-auto mt-4" />
      <p className="font-para text-base text-primary text-center mt-12 text-sm">
       Dann lohnt sich ein Einspruch nicht wirklich... <br></br>
        <br></br> Wir würden Ihnen raten das Bußgeld zu bezahlen.
      </p>
      <Link to="/">
        <div className="flex flex-row mt-12 mb-12 justify-center gap-2 ml-4 group cursor-pointer ">
          <p className="font-para tracking-widest text-primary text-2xl  ">
            HOME
          </p>
          <img
            src={arrow}
            alt=""
            className="h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out "
          />
        </div>
      </Link>
    </div>
  );
}

export default Sorry;
