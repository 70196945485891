import React, {useState, useCallback} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg';
import arrow from '../images/arrow.svg';
import { Link } from 'react-router-dom';


function Punkte() {
  const location = useLocation();
  const data = location.state.data; // Get passed data from Upload component
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);

  const handleClick = useCallback((points) => {
    setSelected(points);
    
    const parsedPoints = parseInt(data.responseText.match(/\d+/)); //Parse point from responseText
    const addedPoints = points + parsedPoints; // Add passed data points to selected points
  
    console.log({"total_points": addedPoints}); // Log total points
  
  }, [data]); // use data as a dependency
  
  const handleWeiter = () => {
    const parsedPoints = parseInt(data.responseText.match(/\d+/)); //Parse point from responseText
    const addedPoints = selected + parsedPoints; // Add passed data points to selected points
  
    if (addedPoints >= 5) {
      navigate('/Wann');
    } else {
      navigate('/RSAbfrage', { state: { data: data } });
    }
  };

  return (
    <div className='p-10 content-center grid '>
      <img src={logo} alt="" className='h-[2rem] mx-auto mt-4'/>

    <p className='font-para text-base text-primary text-center mt-12'>Wie viele Punkte haben Sie bereits? <br></br>(Ohne die Punkte, die das jetztige Bußgeld mit sich führen würde.)</p>
    
    
    <p className='font-para text-base text-primary text-center mt-12 font-black'>AUSWAHL:</p>
    <div className='flex flex-row content-center justify-center mt-4 gap-4'>
  {[0,1,2,3,4,5,6,7].map((point) =>
    <button 
      key={point}
      className={`border-2 border-secondary px-2 rounded-lg bg-transparent text-primary 
                 ${selected === point ? 'bg-secondary text-white ' : 'hover:text-white hover:bg-secondary'} 
                 duration-300 ease-out `}
      onClick={() => handleClick(point)}
    >
      {point}
    </button>
  )}
</div>

      <button onClick={handleWeiter} className='flex flex-row mt-24 justify-center gap-2 ml-4 group cursor-pointer '>
   
      <p className='font-para tracking-widest text-primary text-2xl  '>WEITER</p>
      <img src={arrow} alt="" className='h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out ' />
  
    </button>
    </div>
  );
}

export default Punkte;