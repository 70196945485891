import React, { useState, useEffect } from 'react';
import success from '../images/success.png';
import arrow from '../images/arrow.svg';
import RSEingabe from './RSEingabe'; // Your RSEingabe component
import "react-datepicker/dist/react-datepicker.css";
import {sendUserData} from "../utils/tracking.js"


function Einspruch({ file, data }) {
  useEffect(() => {
    sendUserData('Einspruch könnte lohnen'); 
  }, []);
  const [currentView, setCurrentView] = useState('info');

  // Since `data` is an object, you can directly access the `name` property
  const name = data?.name; // Using optional chaining in case `data` is undefined/null

  const handleDisplayChange = (view) => {
    setCurrentView(view);
  };

  const renderContent = () => {
    switch (currentView) {
      case 'info':
        return (
          <div className="px-12 py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw] max-sm:px-2">
            <img src={success} alt="" className='h-[10rem] mx-auto'/>
            <h2 className='font-header text-secondary font-black text-center'>EINSPRUCH KÖNNTE LOHNEN</h2>
            <p className='font-para text-primary mt-12 text-[18px] px-8 leading-[24px]'>
              {/* Directly display the name extracted from `data` object */}
              {name || "Name nicht verfügbar"}<br/><br/>
              die digitale Prüfung ihres Bußgeldbescheides hat ergeben, dass sich ein Einspruch lohnen
              kann.<br/><br/>
              Sie haben hier die Möglichkeit, <span className='font-bold text-secondary'>in nur einer Minute</span> die JuraSolutions Rechtsanwaltsgesellschaft mbH mit der Vertretung Ihrer rechtlichen Interessen zu beauftragen.<br></br><br></br>
                
                Hierzu würden wir eine Deckung von Ihrer Rechtsschutzversicherung einholen.<br></br><br></br>Für Sie fallen also<span className='font-black text-secondary'> keine Kosten
              </span> an.
             
            </p>
            <div onClick={() => handleDisplayChange('RSEingabe')} className='flex flex-row mt-12 mb-12 justify-center gap-2 ml-4 group cursor-pointer max-h-fit '>
              <p className='font-para tracking-widest text-primary text-2xl'>LOS GEHTS</p>
              <img src={arrow} alt="" className='h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out' />
            </div>
          </div>
        );
      case 'RSEingabe':
        return <RSEingabe file={file} data={data} />;
      default:
        return null;
    }
  };

  return renderContent();
}

export default Einspruch;