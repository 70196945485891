import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {sendUserData} from "../utils/tracking.js"




function Datenschutz() {
  useEffect(() => {
    sendUserData('Datenschutz'); 
  }, []);
  return (
    <div className="prose prose-lg mx-auto p-6 lg:pr-[30%] lg:pl-[20%] font-para text-[15px] blocksatz">
      {" "}
      <div className=" flex flex-row justify-center gap-8 mx-auto text-sm  mt-6 max-sm:max-w-[95vw] max-sm:text-[.5rem] MobileHide">
        <Link to='/'>
          <p
            className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out "
          >
            HOME
          </p>
        </Link>
        <Link to='/AGB'>
          <p
            className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out"
          >
            AGB
          </p>
        </Link>
        <Link to='/Impressum'>
          <p
            className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out"
          >
            Impressum
          </p>
        </Link>
       
      </div>
      <h1 className="mt-10 font-header font-black uppercase text-secondary text-3xl">
        {" "}
        DATENSCHUTZ{" "}
      </h1>{" "}
      <p className="text-sm">
        {" "}
        Datenschutzerklärung im Sinne der Art. 13 und 14 DSGVO
        (Informationspflichten){" "}
      </p>
      <h3 className="font-bold text-base mt-10 mb-4">
        {" "}
        1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie
        des betrieblichen Datenschutzbeauftragten{" "}
      </h3>{" "}
      <p>
        {" "}
        Kontaktdaten des Unternehmens (Verantwortlicher):
        <br /> JuraSolutions Rechtsanwaltsgesellschaft mbH
        <br /> Kalteiche Ring 46, 35708 Haiger, Deutschland.
        <br /> Telefonnummer: +49 (0) 2736 20096 50
        <br /> E-Mail-Adresse: service@jurasolutions.de{" "}
      </p>{" "}
      <p>
        {" "}
        Kontaktdaten des Datenschutzbeauftragten:
        <br /> Dipl.-Kfm. Günther Otten
        <br /> Telefonnummer: +49 (0) 221 9783130
        <br /> E-Mail-Adresse: guenther.otten@t-online.de{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
        von deren Verwendung{" "}
      </h3>{" "}
      <p>
        {" "}
        a) Beim Besuch der Website
        <br /> Beim Aufruf unserer Website „vickychat.de“ werden durch den auf
        Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen
        an den Server unserer Website gesendet, die möglicherweise eine
        Identifizierung zulassen. Folgende Informationen werden dabei ohne Ihr
        Zutun erfasst und bis zur automatisierten Löschung gespeichert:{" "}
      </p>{" "}
      <ul>
        {" "}
        <li>IP-Adresse des anfragenden Rechners</li>{" "}
        <li>Datum und Uhrzeit des Zugriffs</li>{" "}
        <li>Name und URL der abgerufenen Datei</li>{" "}
        <li>Website, von der aus der Zugriff erfolgte</li>{" "}
        <li>Verwendeter Browser</li>{" "}
        <li>
          ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres
          Access-Providers
        </li>{" "}
      </ul>{" "}
      <p>
        {" "}
        Die vorübergehende Speicherung der Daten ist für den Ablauf eines
        Websitebesuchs erforderlich, um eine Auslieferung der Website zu
        ermöglichen. Eine weitere Speicherung der Protokolldateien erfolgt, um
        die Funktionsfähigkeit der Website und die Sicherheit der
        informationstechnischen Systeme sicherzustellen. Die genannten Daten
        werden durch uns zu folgenden Zwecken verarbeitet:{" "}
      </p>{" "}
      <ul>
        {" "}
        <li>
          Gewährleistung eines reibungslosen Verbindungsaufbaus der Website
        </li>{" "}
        <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>{" "}
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>{" "}
        <li>Zu weiteren administrativen Zwecken</li>{" "}
      </ul>{" "}
      <p>
        {" "}
        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
        lit. f DS-GVO. Unser berechtigtes Interesse folgt aus oben gelisteten
        Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen
        Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. Derzeit
        setzen wir beim Besuch unserer Website keine Cookies ein.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        3. Gibt es neben dem Verantwortlichen weitere Empfänger der
        personenbezogenen Daten?{" "}
      </h3>{" "}
      <p>
        {" "}
        Die Website vickychat.de wird gehostet bei:
        <br /> IONOS by 1&1
        <br /> Montabaur 56410 (Westerwaldkreis), Elgendorfer Str. 57
        <br /> IONOS by 1&1 empfängt zu diesem Zweck die oben genannten Daten
        als Auftragsverarbeiter.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        4. Wie lange werden die Daten gespeichert?{" "}
      </h3>{" "}
      <p>
        {" "}
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks
        ihrer Erhebung nicht mehr erforderlich sind. Bei der Bereitstellung der
        Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Die
        Betreiberin versucht die übermittelten Daten so sparsam wie möglich zu
        verwenden. Die künstliche Intelligenz des Portals wird von OpenAI als
        Betreiberin der Plattform Chat-GPT bereitgestellt. Zu Beginn muss sich
        der Nutzer registrieren. Diese Registrierungsdaten werden auf dem Server
        des Portals in Deutschland gespeichert, solange der Nutzer seine
        Registrierung aufrechterhält. Löscht der Nutzer seine Registrierung,
        werden seine Daten gelöscht. Im Falle des Dienstes "erste rechtliche
        Orientierung und Zusammenfassung von Dokumenten" werden die vom
        berechtigten Nutzer eingegebenen Daten nur anonym an die künstliche
        Intelligenz übermittelt. Das Portal Vickychat speichert diese Daten
        nicht, wenn kein weiterer Service in Anspruch genommen wird. So ist die
        Anonymität des Nutzers gewahrt. Werden weitere Dienste gebucht, wird der
        Chatverlauf bzw. das zu prüfende Dokument und die Antwort von Vickychat
        an die Betreiberin übermittelt. Diese speichert diese Daten zusammen mit
        den Kontaktdaten des berechtigten Nutzers in ihrer anwaltlichen
        Datenbank zum Zwecke der standesrechtlich gebotenen Dokumentation und
        zur Abrechnung.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        5. Welche Daten werden für welchen Zweck verarbeitet?{" "}
      </h3>{" "}
      <p>
        {" "}
        Es werden nur die Daten verarbeitet, die für die Erbringung des
        angeforderten Dienstes nötig sind. Die Rechtsgrundlage für die
        Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser
        berechtigtes Interesse folgt aus oben gelisteten Zwecken zur
        Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem
        Zweck, Rückschlüsse auf Ihre Person zu ziehen.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        6. Wie lange werden die Daten gespeichert?{" "}
      </h3>{" "}
      <p>
        {" "}
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks
        ihrer Erhebung nicht mehr erforderlich sind.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        7. Gibt es neben dem Verantwortlichen weitere Empfänger der
        personenbezogenen Daten?{" "}
      </h3>{" "}
      <p>
        {" "}
        IONOS empfängt zu diesem Zweck die oben genannten Daten als
        Auftragsverarbeiter. Die empfohlenen Rechtsanwaltskanzleien, sofern Sie
        die Weitergabe zugestimmt haben, oder die Empfehlung mit einer
        Kontaktaufnahme wünschen. Darüber hinaus erfolgt eine Übermittlung Ihrer
        personenbezogenen Daten an Dritte z.B. Assisteure- im Rahmen der
        Schadenbearbeitung/-Abwicklung soweit erforderlich.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4"> 8. Cookies </h3>{" "}
      <p>
        {" "}
        Zurzeit benutzen wir keine Cookies, aber behalten uns vor, durch diese
        Webseite Cookies zu verwenden, also kleine Dateien, die es ermöglichen,
        auf dem Zugriffsgerät der Nutzer (PC, Smartphone o.ä.) spezifische, auf
        das Gerät bezogene Informationen zu speichern. Sie dienen zum einem der
        Benutzerfreundlichkeit von Webseiten und damit den Nutzern (z.B.
        Speicherung von Logindaten). Zum anderen dienen sie, um die
        statistischen Daten der Webseitennutzung zu erfassen und sie zwecks
        Verbesserung des Angebotes analysieren zu können. Die Nutzer können auf
        den Einsatz der Cookies Einfluss nehmen. Die meisten Browser verfügen
        über eine Option, mit der das Speichern von Cookies eingeschränkt oder
        komplett verhindert wird. Allerdings wird darauf hingewiesen, dass die
        Nutzung und insbesondere der Nutzungskomfort ohne Cookies eingeschränkt
        wird.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        9. Betroffenenrechte{" "}
      </h3>{" "}
      <p> Sie haben das Recht: </p>{" "}
      <ul>
        {" "}
        <li>
          gemäß Art. 15 DS-GVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen.
        </li>{" "}
        <li>
          gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu verlangen.
        </li>{" "}
        <li>
          gemäß Art. 17 DS-GVO die Löschung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist.
        </li>{" "}
        <li>
          gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DS-GVO Widerspruch
          gegen die Verarbeitung eingelegt haben.
        </li>{" "}
        <li>
          gemäß Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen.
        </li>{" "}
        <li>
          gemäß Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortsetzen dürfen.
        </li>{" "}
        <li>
          gemäß Art. 77 DS-GVO sich bei der für uns zuständigen Aufsichtsbehörde
          zu beschweren: Der Hessische Beauftragte für Datenschutz und
          Informationsfreiheit, Postfach 31 63, 65021 Wiesbaden.
        </li>{" "}
      </ul>
      <h3 className="font-bold text-base mt-8 mb-4"> 10. Widerspruchsrecht </h3>{" "}
      <p>
        {" "}
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
        Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DS-GVO verarbeitet werden,
        haben Sie das Recht, gemäß Art. 21 DS-GVO Widerspruch gegen die
        Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
        Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
        sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
        haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
        besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem
        Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail
        an: service@jurasolutions.de.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        11. Datensicherheit{" "}
      </h3>{" "}
      <p>
        {" "}
        Wenn wir Sie beraten, können Sie sich auf einen zertifizierten
        Datenschutz verlassen. Wir garantieren Ihnen, dass wir Ihre Daten nicht
        an Dritte weitergeben. Wir sind uns darüber bewusst, dass Ihnen der
        Schutz Ihrer Privatsphäre bei der Benutzung unserer Internetseiten ein
        wichtiges Anliegen ist. Deshalb nehmen wir unsere Aufgabe, die
        Vertraulichkeit Ihrer personenbezogenen Daten sicherzustellen, sehr
        ernst. Vor dem Hintergrund eskalierender Datenverluste und
        Systemausfälle durch Angriffe auf die IT-Infrastrukturen von Unternehmen
        gewinnt ein tragfähiger Sicherheitsstandard zunehmend an Bedeutung.
        IT-Sicherheit und Datenschutz sind Basiselemente für eine
        Vertrauensbildung von Kunden und Kooperationspartnern und bilden einen
        starken Pfeiler in der Wettbewerbsfähigkeit.{" "}
      </p>
      <h3 className="font-bold text-base mt-8 mb-4">
        {" "}
        12. Aktualität und Änderung dieser Datenschutzerklärung{" "}
      </h3>{" "}
      <p className="mb-12">
        {" "}
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
        September 2023. Durch die Weiterentwicklung unserer Website und Angebote
        darüber oder aufgrund geänderter gesetzlicher beziehungsweise
        behördlicher Vorgaben kann es notwendig werden, diese
        Datenschutzerklärung zu ändern. Die jeweils aktuelle
        Datenschutz-Erklärung kann jederzeit auf der Website unter
        „vickychat.de“ von Ihnen abgerufen und ausgedruckt werden.{" "}
      </p>{" "}
    </div>
  );
}

export default Datenschutz;
