import React, { useState, useEffect } from "react";
import Success from "./Success";
import Success2 from "./Success2";
import logo from "../images/logo.svg";
import arrow from "../images/arrow.svg";
import {sendUserData} from "../utils/tracking.js"


function Vsn({
  file,
  email,
  phone,
  data,
  vsn,
  versicherer,
  documentId,
  signatureRequestId,
  firstName,
  lastName,
}) {
  const [inputData, setInputData] = useState({
    versicherer: versicherer,
    vsn: vsn,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(null); // Initially, the user hasn't made a decision
  const [submissionType, setSubmissionType] = useState(null); // Define the state for tracking submission type

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleUserDecision = (decision) => {
    setShowForm(decision);
    if (!decision) {
      const newData = {
        versicherer: "Wird noch benötigt",
        vsn: "Wird noch benötigt",
      };
      setInputData(newData); // Update state, though it's not used immediately
      setSubmissionType(false); // Explicitly mark the submission type for "Nein" decision
      handleSubmit(null, false, newData); // Pass updated data directly to handleSubmit
    }
  };
  useEffect(() => {
    sendUserData('VSN Abfrage'); 
  }, []);

  const handleSubmit = async (e, decisionMade = true, directData = null) => {
    if (e) e.preventDefault();

    setFormSubmitted(true);
    if (decisionMade) setSubmissionType(showForm);

    // Use directData if provided, else use state data
    const finalData = directData || inputData;

    const htmlContent = `<h1>Auftragsbestätigung</h1>
    <p> ${data?.name || "Nicht verfügbar"}<br><br>

     wir haben Ihre Anfrage erhalten somit umgehend:</p> <br></br> <br></br>


   <ul
     className="leading-6"
     style={{ listStyleType: "disc", paddingLeft: "20px" }}
   >
     <li>Einspruch bei der zuständigen Behörde einlegen</li>
     <li>die Bußgeldakte anfordern</li>
     <li>
       eine Deckungszusage bei der Rechtsschutzversicherung einholen
     </li>
   </ul>


   <p>Anbei finden Sie nochmal eine Zusammenfassung Ihrer Daten, sowie eine Kopie der unterschriebenen Dokumente und dem Foto, dass Sie hochgeladen haben. </p>

     <h2 style="margin-top:4rem;">Angaben zur Person:</h2>
     <p>Name: ${firstName} ${lastName}</p>
     <p>Email: ${email}</p>
     <p>Telefon: ${phone}</p>


     <h2 style="margin-top:4rem;">Angaben zur Versicherung</h2>
     <p>Versicherer: ${finalData.versicherer}</p>
     <p>VSN: ${finalData.vsn}</p>


     <h2 style="margin-top:4rem;">Bußgeldbescheid Details:</h2>
     <p>Ausstellende Behörde: ${
       data?.ausstellendebehörde || "Nicht verfügbar"
     }</p>
     <p>Verstoß: ${data?.verstoß || "Nicht verfügbar"}</p>
     <p>Bußgeld: ${data?.bußgeld || "Nicht verfügbar"}</p>
     <p>Punkte: ${data?.punkte || "Nicht verfügbar"}</p>
     <p>Fahrverbot: ${data?.fahrverbot || "Nicht verfügbar"}</p>

<br><br><br>

     Mit freundlichen Grüßen,<br><br>
     Ihr Bussgeld.pro Team & JuraSolutions Rechtsanwaltsgesellschaft mbH`; // Your HTML content here
    const htmlContent2 = ` <h1>Neue Einspruchsanfrage</h1>
      
    <h2 style="margin-top:4rem;">Angaben zur Person:</h2>
    <p>Name: ${firstName} ${lastName}</p>
    <p>Email: ${email}</p>
    <p>Telefon: ${phone}</p>


    <h2 style="margin-top:4rem;">Angaben zur Versicherung</h2>
    <p>Versicherer: ${finalData.versicherer}</p>
    <p>VSN: ${finalData.vsn}</p>


    <h2 style="margin-top:4rem;">Bußgeldbescheid Details:</h2>
    <p>Ausstellende Behörde: ${
      data?.ausstellendebehörde || "Nicht verfügbar"
    }</p>
    <p>Verstoß: ${data?.verstoß || "Nicht verfügbar"}</p>
    <p>Bußgeld: ${data?.bußgeld || "Nicht verfügbar"}</p>
    <p>Punkte: ${data?.punkte || "Nicht verfügbar"}</p>
    <p>Fahrverbot: ${data?.fahrverbot || "Nicht verfügbar"}</p>

<br><br><br>

    Mit freundlichen Grüßen,<br><br>
    Ihr Bussgeld.pro Team & JuraSolutions Rechtsanwaltsgesellschaft mbH`; // Your HTML content here, ensure variables used are defined

    const formData = new FormData();
    formData.append("file", file);
    formData.append("vsn", inputData.vsn);
    formData.append("versicherer", inputData.versicherer);
    formData.append("documentId", documentId);
    formData.append("signatureRequestId", signatureRequestId);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);

    // Append other data required for the email
    formData.append("recipient", email); // Assuming `email` is the recipient's email
    formData.append("subject", "BUSSGELD.PRO Einspruch");
    formData.append("htmlContent", htmlContent);
    formData.append("htmlContent2", htmlContent2);
    // Include other formData append operations here...

    try {
      const response = await fetch("/api/send-email-with-file", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // The rest of your component remains the same until the rendering logic
  if (formSubmitted) {
    return submissionType === false ? <Success2 /> : <Success />;
  }
  return (
    <>
      <div className="px-12 py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw] max-sm:px-2">
        <img src={logo} alt="" className="h-[2rem] mx-auto mt-4" />

        {showForm === null && (
          <>
            <div className="font-para text-[18px] max-sm:text-[14px] leading-[24px] text-primary mt-12 px-8 max-sm:px-2 max-sm:max-w-[95vw]">
              <p>
                Fast geschafft. <br></br>
                <br></br>Haben Sie Ihre Versicherungsscheinnummer derzeit zur
                Hand? <br />
                <br />
              </p>
            </div>
            <div className=" flex flex-row mx-auto mb-4 gap-8">
              <button
                onClick={() => handleUserDecision(true)}
                className="border-2 font-para border-secondary bg-transparent rounded-lg p-2 mx-auto hover:bg-secondary hover:text-white duration-300 ease-in-out mt-12 text-[18px]"
              >
                Ja
              </button>
              <button
                onClick={() => handleUserDecision(false)}
                className="border-2 font-para border-secondary bg-transparent rounded-lg p-2 mx-auto hover:bg-secondary hover:text-white duration-300 ease-in-out mt-12 text-[18px]"
              >
                Nein
              </button>
            </div>
          </>
        )}

        {showForm === true && (
          <form
            onSubmit={handleSubmit}
            className="grid mx-auto mt-8 max-sm:max-w-[95vw] "
          >
            <p className="font-para font-black uppercase tracking-widest text-[.7rem] leading-[30px] ml-[5px]">
              Versicherer:
            </p>
            <input
              type="text"
              id="versicherer"
              name="versicherer"
              value={inputData.versicherer}
              onChange={handleInputChange}
              className="border-2 max-sm:max-w-[80vw] border-slate-300 rounded-sm px-2  w-[500px] text-[.7rem] font-para mx-auto"
              placeholder="z.B. Deurag"
            />
            <p className="font-para font-black uppercase tracking-widest text-[.7rem] mt-4 leading-[30px] ml-[5px]">
              Versicherungsscheinnummer:
            </p>

            <input
              type="text"
              id="vsn"
              name="vsn"
              value={inputData.vsn}
              onChange={handleInputChange}
              className="border-2 max-sm:max-w-[80vw] border-slate-300 rounded-sm px-2 w-[500px] text-[.7rem] font-para mx-auto"
              placeholder="z.B. 7281724"
            />

            <button
              type="submit"
              className="flex flex-row mt-12 mb-12 justify-center gap-2 ml-4 group cursor-pointer"
            >
              <p className="font-para tracking-widest text-primary text-2xl">
                ABSENDEN
              </p>
              <img
                src={arrow}
                alt=""
                className="h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out"
              />
            </button>
          </form>
        )}
      </div>
    </>
  );
}

export default Vsn;
