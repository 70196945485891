import React, { useEffect } from 'react';
import logo from '../images/logo.svg';
import arrow from '../images/arrow.svg';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import {sendUserData} from "../utils/tracking.js"


function ZuAlt() {
  useEffect(() => {
    sendUserData('Schreiben ist zu alt'); 
  }, []);

  return (
    <div className='p-10 content-center grid justify-center '>
      <img src={logo} alt="" className='h-[2rem] mx-auto mt-4'/>
        <p className='font-para text-base text-primary text-center mt-12'>Leider kommt ein Einspruch nicht länger in Frage. <br></br><br></br> Wir empfehlen Ihnen, das Bußgeld zu zahlen.</p>
      <Link to="/">
        <div className='flex flex-row mt-24 justify-center gap-2 ml-4 group cursor-pointer '>
          <p className='font-para tracking-widest text-primary text-2xl  '>HOME</p>
          <img src={arrow} alt="" className='h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out ' />
        </div>
      </Link>
    </div>
  );
}

export default ZuAlt;