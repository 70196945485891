import React, { useState, useEffect } from 'react';
import logo from '../images/logo.svg';
import arrow from '../images/arrow.svg';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import Einspruch from './Einspruch';
import ZuAlt from './ZuAlt';
import {sendUserData} from "../utils/tracking.js"


function Wann({ file, data }) { // Accept the callback as a prop
  useEffect(() => {
    sendUserData('Wann');
  }, []);

  const [selectedDate, setSelectedDate] = useState(null);
  const [resultComponent, setResultComponent] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setResultComponent(null); // Reset resultComponent when date changes
  }

  const handleSubmit = () => {
    if (selectedDate) {
      const today = new Date();
      const differenceInTime = today.getTime() - selectedDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if(differenceInDays <= 14){
          console.log("Yes, within 14 days");
          setResultComponent('Einspruch');
      }else{
          console.log("No, not within 14 days");
          setResultComponent('ZuAlt');
      }
    }
  }

  const renderResultComponent = () => {
    switch (resultComponent) {
      case 'Einspruch':
        return <Einspruch  file={file} data={data}  />; // Pass the callback to Einspruch
      case 'ZuAlt':
        return <ZuAlt />;
      default:
        return null;
    }
  }

  if (resultComponent === null) {
    return (
      <div className="px-12 py-4 w-[30rem] max-sm:max-w-[95vw]  text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12">
        <img src={logo} alt="" className='h-[2rem] mx-auto mt-4'/>
          <p className='font-para text-[20px] text-primary text-center mt-12'>Wann haben Sie das Schreiben erhalten?</p>
          <div className='justify-center text-center'>
            <DatePicker
                selected={selectedDate}
                onChange={date => handleDateChange(date)}
                dateFormat="dd/MM/yyyy"
                isClearable
                placeholderText="DD/MM/YYYY"
                className='mx-auto mt-10 border-2 px-2 text-[.8rem] rounded-lg border-secondary text-center'
              />
          </div>
          <div onClick={handleSubmit} className='flex flex-row mt-12 mb-12 justify-center gap-2 ml-4 group cursor-pointer '>
            <p className='font-para tracking-widest text-primary text-2xl  '>ABSENDEN</p>
            <img src={arrow} alt="" className='h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out ' />
          </div>
      </div>
    );
  } else {
    // Render the result component (either Einspruch or ZuAlt)
    return renderResultComponent();
  }
}

export default Wann;