import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendUserData } from "../utils/tracking.js";
import logo from "../images/logo.svg";
import JSOnly from "../images/JSOnly.svg";
import SearchIcon from "../images/search.svg";
import { motion } from "framer-motion";
import { Turn as Hamburger } from "hamburger-react";
import Article30erZone from "../Articles/Article30erZone.jsx";
import ArticleAbstandsvergehen from "../Articles/ArticleAbstandsvergehen.jsx";
import ArticleAbbiegen from "../Articles/ArticleAbbiegen.jsx";
import ChatWindow from "./ChatWindow";

function Katalog() {
  useEffect(() => {
    sendUserData("Bussgeldkatalog");
  }, []);

  const [searchResults, setSearchResults] = useState([]);
  
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const [selectedTable, setSelectedTable] = useState("30er Zone");
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedTable]);

  const data = {
    "30er Zone": {
      header: "30er Zone: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "70 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "50er-Zone außerorts": {
      header: "50er-Zone: Überschreitung der Höchstgeschwindigkeit außerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "20 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "50er Zone innerorts": {
      header: "50er Zone: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "70 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Abbiegen, Wenden, Rückwärtsfahren": {
      header: "Abbiegen, Wenden, Rückwärtsfahren",
      fines: [
        {
          violation:
            "Sie biegen ab und haben sich nicht ordnungsgemäß eingeordnet",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Sie gefährden dabei andere Straßenverkehrsteilnehmer",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Dabei liegt Sachbeschädigung vor",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie biegen ab und haben ein entgegenkommendes Fahrzeug nicht durchgelassen",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Sie gefährden dabei andere Straßenverkehrsteilnehmer",
          fine: "140 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "Dabei liegt Sachbeschädigung vor",
          fine: "170 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie gefährden andere Straßenverkehrsteilnehmer beim Wenden, Abbiegen auf eine Grundstückseinfahrt oder beim Zurücksetzen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie fahren in eine Kreuzung oder Einmündung, obwohl der Verkehr stockt, dabei liegt eine Behinderung vor",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie biegen innerorts mit einem Kfz über 3,5 t zGG rechts ab, ohne Schrittgeschwindigkeit einzuhalten, obwohl mit Fahrrad- oder Fußgängerverkehr zu rechnen ist",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung",
          fine: "85 €",
          points: "1",
          suspension: "",
        },
        { violation: "Mit Unfall", fine: "105 €", points: "1", suspension: "" },
        {
          violation:
            "Sie sind beim Linksabbiegen nicht vorneinander abgebogen, dabei liegt Gefährdung vor",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie biegen ab und achten dabei nicht auf Fußgänger, dabei liegt Gefährdung vor",
          fine: "140 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "Dabei liegt Sachbeschädigung vor",
          fine: "170 €",
          points: "1",
          suspension: "1 Monat",
        },
      ],
    },
    "Abgesenkter Bordstein": {
      header: "Abgesenkter Bordstein",
      fines: [
        {
          violation: "Sie parkten vor einer Bordsteinabsenkung",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... und behinderten dadurch Andere",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie parkten länger als drei Stunden vor einer Bordsteinabsenkung",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... und behinderten dadurch Andere",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie fuhren über einen abgesenkten Bordstein auf die Fahrbahn, ohne den Blinker zu setzen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie fuhren über einen abgesenkten Bordstein hinweg auf die Fahrbahn und gefährdeten dadurch Andere",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie fuhren über einen abgesenkten Bordstein hinweg auf die Fahrbahn und es kam zu einem Unfall",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Abstandsvergehen: {
      header: "Abstandsvergehen",
      fines: [
        {
          violation: "Abstandsverstoß bei weniger als 80 km/h",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Abstandsverstoß mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Abstandsverstoß mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h, Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h, Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h, Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h, Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h, Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h, Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h, Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h, Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h, Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h, Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h, Abstand weniger als 5/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h, Abstand weniger als 4/10 des halben Tachowertes",
          fine: "180 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h, Abstand weniger als 3/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h, Abstand weniger als 2/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h, Abstand weniger als 1/10 des halben Tachowertes",
          fine: "400 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    Alkohol: {
      header: "Verstöße im Zusammenhang mit Alkohol am Steuer",
      fines: [
        {
          violation: "Verstoß gegen die 0,5 Promillegrenze - beim 1. Mal",
          fine: "500 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "Verstoß gegen die 0,5 Promillegrenze - beim 2. Mal",
          fine: "1000 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "Verstoß gegen die 0,5 Promillegrenze - beim 3. Mal",
          fine: "1500 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation:
            "Gefährdung des Verkehrs unter Alkoholeinfluss (ab 0,3 Promille)",
          fine: "",
          points: "3",
          suspension:
            "Entziehung des Führerscheins, Freiheitsstrafe oder Geldstrafe",
        },
        {
          violation: "Alkoholgehalt im Blut ab 1,1 Promille",
          fine: "",
          points: "3",
          suspension:
            "Entziehung des Führerscheins, Freiheitsstrafe oder Geldstrafe",
        },
      ],
    },
    Drogen: {
      header: "Drogen: Verstoß gegen das Drogengesetz im Straßenverkehr",
      fines: [
        {
          violation: "beim 1. Mal",
          fine: "500 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "beim 2. Mal",
          fine: "1000 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "beim 3. Mal",
          fine: "1500 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "Gefährdung des Verkehrs unter Drogeneinfluss",
          fine: "",
          points: "3",
          suspension:
            "Entziehung der Fahrerlaubnis, Freiheitsstrafe oder Geldstrafe",
        },
      ],
    },
    "Außerorts Geschwindigkeit mit Anhänger überschritten": {
      header: "Außerorts Geschwindigkeit mit Anhänger überschritten",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        {
          violation: "16 - 20 km/h",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "175 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "255 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Innerorts Geschwindigkeit mit Anhänger überschritten": {
      header: "Innerorts Geschwindigkeit mit Anhänger überschritten",
      fines: [
        { violation: "bis 10 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "16 - 20 km/h",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "175 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "235 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "340 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "51 - 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    Anhänger: {
      header: "Allgemeiner Bußgeldkatalog Anhänger",
      fines: [
        {
          violation:
            "Das Fahrzeug geführt, obwohl die Anhängerkupplung nicht den Vorschriften entsprach",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Die Inbetriebnahme des Fahrzeugs angeordnet oder zugelassen, obwohl die Anhängerkupplung nicht den Vorschriften entsprach",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Das Fahrzeug geführt, obwohl durch die mangelhafte Verbindung der Fahrzeugkombination die Verkehrssicherheit beeinträchtigt wurde",
          fine: "135 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "165 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Unfall",
          fine: "200 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Den Anhänger ohne Unterlegkeile geführt",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Anhänger ohne Zugfahrzeug länger als zwei Wochen geparkt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Termin zur Sicherheitsprüfung für Anhänger mit zulässiger Gesamtmasse von mehr als 10 t versäumt",
          fine: "",
          points: "",
          suspension: "",
        },
        {
          violation: "...um bis zu zwei Monate",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...um zwei bis vier Monate",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...um vier bis acht Monate",
          fine: "60 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...um mehr als acht Monate",
          fine: "75 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Anschnallpflicht missachtet": {
      header: "Anschnallpflicht missachtet",
      fines: [
        {
          violation: "Während der Fahrt nicht angeschnallt gewesen",
          fine: "30 €",
          points: "0",
          suspension: "",
        },
        {
          violation: "ein Kind unangeschnallt im Auto mitgenommen",
          fine: "30 €",
          points: "0",
          suspension: "",
        },
        {
          violation: "mehrere Kinder unangeschnallt im Auto mitgenommen",
          fine: "35 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "ein Kind ohne jegliche Sicherung im Auto mitgenommen (z. B. kein Gurt + kein Kindersitz)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mehrere Kinder ohne jegliche Sicherung im Auto mitgenommen (z. B. kein Gurt + kein Kindersitz)",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Autobahn: Geschwindigkeitsüberschreitung (Pkw)": {
      header: "Geschwindigkeitsüberschreitung auf der Autobahn (Pkw)",
      fines: [
        { violation: "bis 10 km/h", fine: "20 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },

    "Autobahn: Geschwindigkeitsüberschreitung (Lkw, Pkw mit Anhänger)": {
      header:
        "Geschwindigkeitsüberschreitung auf der Autobahn (Lkw, Pkw mit Anhänger)",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        {
          violation: "16 - 20 km/h",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "175 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "255 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },

    "Autobahn: Abstandsverstoß": {
      header: "Abstandsverstoß auf der Autobahn",
      fines: [
        {
          violation: "bei weniger als 80 km/h",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 80 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 80 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 80 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 80 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 80 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 100 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 100 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 100 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "mit mehr als 100 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "mit mehr als 100 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation:
            "mit mehr als 130 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 130 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "180 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit mehr als 130 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "mit mehr als 130 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "mit mehr als 130 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "400 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },

    "Autobahn: Rücksichtsloses Wenden, Rückwärtsfahren und Fahren entgegen der Fahrtrichtung":
      {
        header:
          "Rücksichtsloses Wenden, Rückwärtsfahren und Fahren entgegen der Fahrtrichtung auf der Autobahn",
        fines: [
          {
            violation:
              "Rücksichts­loses Wenden, Rückwärts­fahren, Fahren entgegen der Fahrt­richtung",
            fine: "vari­iert",
            points: "3",
            suspension:
              "Freiheitsstrafe bis zu 5 Jahren, Entzug der Fahrerlaubnis",
          },
          {
            violation:
              "Rückwärts & entge­gen der Fahrt­richtung fahren in Ein- oder Ausfahrt",
            fine: "75 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Rückwärts & entge­gen der Fahrt­richtung fahren in Ein- oder Ausfahrt mit Gefähr­dung",
            fine: "90 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Rückwärts & entge­gen der Fahrt­richtung fahren in Ein- oder Ausfahrt mit Verur­sachung eines Unfalls",
            fine: "110 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden in Ein- oder Aus­fahrt",
            fine: "75 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden in Ein- oder Aus­fahrt mit Gefähr­dung",
            fine: "90 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Wenden in Ein- oder Aus­fahrt mit Verur­sachung eines Un­falls",
            fine: "110 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden auf Neben­straße oder Seiten­streifen",
            fine: "130 €",
            points: "1",
            suspension: "",
          },
        ],
      },

    "Autobahn: Halten und Parken auf der Autobahn": {
      header: "Halten und Parken auf der Autobahn",
      fines: [
        {
          violation:
            "Unzuläs­siges Halten auf der Kraft­fahrstraße bzw. Autobahn",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzuläs­siges Halten auf der Kraft­fahrstraße bzw. Autobahn mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzuläs­siges Parken auf der Kraft­fahrstraße bzw. Autobahn",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Unzuläs­siges Parken auf der Kraft­fahrstraße bzw. Autobahn mit Gefährdung",
          fine: "85 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Unzuläs­siges Parken auf der Kraft­fahrstraße bzw. Autobahn mit Unfall",
          fine: "105 €",
          points: "1",
          suspension: "",
        },
      ],
    },

    "Autobahn: Weitere Verstöße auf der Autobahn": {
      header: "Weitere Verstöße auf der Autobahn",
      fines: [
        {
          violation:
            "Seiten­strei­fen zum schnel­leren Vor­wärts­kom­men be­nut­zen",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht mög­lichst weit rechts fah­ren, ob­wohl dies mög­lich gewe­sen wäre, mit Ge­fähr­dung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auto­bahn / Kraft­fahr­stra­ße mit Fahr­zeug befah­ren, des­sen Höchst­ge­schwin­digkeit 60 km/h oder we­niger be­trägt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Auto­bahn / Kraft­fahr­stra­ße mit Fahr­zeug befah­ren, des­sen zuläs­sige Höhe zusam­men mit der La­dung über­schrit­ten ist (Ge­samt­höhe nicht mehr als 4,20 m)",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Auto­bahn oder Kraft­fahr­straße mit ei­nem Fahr­zeug benutzt, des­sen Höhe zu­sam­men mit der La­dung mehr als 4,20 m be­trägt",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Ein- oder Aus­fahren an unzu­lässi­gen Stel­len",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Ein- oder Aus­fahren an unzu­lässi­gen Stel­len mit Gefähr­dung an­derer Ver­kehrs­teilneh­mer",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Ein- oder Aus­fahren an unzu­lässi­gen Stel­len mit Ver­ur­sach­ung ei­nes Un­falls",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Ein­fah­ren Vor­fahrt auf der durch­gehen­den Fahr­bahn nicht be­ach­ten",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Ein­fah­ren Vor­fahrt auf der durch­gehen­den Fahr­bahn nicht be­ach­ten mit Gefähr­dung an­derer Ver­kehrs­teilneh­mer",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Ein­fah­ren Vor­fahrt auf der durch­gehen­den Fahr­bahn nicht be­ach­ten mit Ver­ur­sach­ung ei­nes Un­falls",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Als Fuß­änger Auto­bahn betre­ten oder Kraft­fahr­straße an da­für nicht vorge­sehener Stel­le be­tre­ten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Autokennzeichen: {
      header: "Autokennzeichen: Verstöße gegen die Vorschriften",
      fines: [
        {
          violation: "Autokenn­zeichen sind schlecht lesbar",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Prüfplakette / Prüfmarke ist verdeckt oder verschmutzt",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Hinteres amtliches Kennzeichen entspricht nicht den Vorschriften",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Vorderes amtliches Kennzeichen entspricht nicht den Vorschriften",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kennzeichen befinden sich in keinem ordnungs­gemäßen Zustand",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kennzeichen­beleuchtung entspricht nicht den Vorschriften",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Kennzeichen fehlt",
          fine: "60 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kennzeichen ist mit Glas, Folie oder ähnlichen Abdeckungen versehen",
          fine: "65 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zulassungs­freies Fahrzeug ist ohne dafür erforderliche EG-Typgenehmigung / Einzel­genehmigung in Betrieb gesetzt worden",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Zulassungs­freies Fahrzeug ist ohne vorgeschrie­benes Kennzeichen auf einer öffentlichen Straße gefahren",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zulassungs­freies Fahrzeug ist ohne gültiges Versicherungs­kennzeichen auf einer öffentlichen Straße gefahren",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zulassungs­freies Fahrzeug ist ohne erforderliche Bescheinigung auf einer öffentlichen Straße gefahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zulassungs­freier Anhänger besitzt kein zulässiges Kennzeichen an der Rückseite",
          fine: "60 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kennzeichen vom zulassungs­freien Anhänger ist mit Glas, Folie oder ähnlichen Abdeckungen versehen",
          fine: "65 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Keine Bescheinigung über das Versicherungs­kennzeichen mitgeführt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Keine Bescheinigung über das Versicherungs­kennzeichen auf Verlangen einer zuständigen Person ausgehändigt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug in Betrieb genommen, obwohl das Versicherungs­kennzeichen nicht den Vorschriften entsprach",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Bahnübergang: {
      header:
        "Bahnübergang: Verstöße gegen die Verkehrsregeln an einem Bahnübergang",
      fines: [
        {
          violation: "unzulässig überholt",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "85 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "105 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Vorrang des Schienenfahrzeuges bei Bahnübergang mit Andreaskreuz nicht beachtet",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "mit nicht angepasster Geschwindigkeit an einen Bahnübergang herangefahren",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Verstoß gegen die Wartepflicht",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verstoß gegen die Wartepflicht, obwohl rotes Blinklicht oder gelbe bzw. rote Lichtzeichen gegeben wurden",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "...mit Gefährdung",
          fine: "290 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "350 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Verstoß gegen die Wartepflicht, obwohl die Schranken sich senkten, ein Bahnbediensteter 'Halt' gebot oder ein hörbares Signal, wie das Pfeifsignal des herannahenden Zuges, ertönte",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "...mit Gefährdung",
          fine: "290 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "350 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Bahnübergang, trotz geschlossener Schranke / Halbschranke überquert",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation:
            "Als nichtmotorisierter Verkehrsteilnehmer den Bahnübergang trotz geschlossener Schranke / Halbschranke überquert",
          fine: "350 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Baustelle für Kraftfahrzeugfahrer": {
      header:
        "Baustelle für Kraftfahrzeugfahrer: Verstoß gegen Geschwindigkeitsbegrenzung in einer Baustelle",
      fines: [
        {
          violation:
            "Fahren mit hoher, nicht angepasster Geschwindigkeit trotz angekündigter Gefahrenstelle, z.B. einer Baustelle",
          fine: "100 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "In Baustellen von rechts überholt - innerorts",
          fine: "30 Euro",
          points: "",
          suspension: "",
        },
        {
          violation:
            "In Baustellen von rechts überholt - innerorts mit Sachbeschädigung",
          fine: "35 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "In Baustellen von rechts überholt - außerorts",
          fine: "100 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In Baustellen von rechts überholt - außerorts mit Gefährdung anderer",
          fine: "120 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In Baustellen von rechts überholt - außerorts mit Sachbeschädigung",
          fine: "145 Euro",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Geschwindigkeitsüberschreitung in einer Baustelle (außerorts)": {
      header:
        "Bußgeldkatalog Geschwindigkeitsüberschreitung in einer Baustelle (außerorts)",
      fines: [
        {
          violation: "bis 10 km/h",
          fine: "20 €",
          points: "",
          suspension: "eher nicht",
        },
        {
          violation: "11 - 15 km/h",
          fine: "40 €",
          points: "",
          suspension: "Hier prüfen **",
        },
        {
          violation: "16 - 20 km/h",
          fine: "60 €",
          points: "",
          suspension: "Hier prüfen **",
        },
        {
          violation: "21 - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "Hier prüfen **",
        },
        {
          violation: "26 - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "(1 Monat)* Hier prüfen **",
        },
        {
          violation: "31 - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "(1 Monat)* Hier prüfen **",
        },
        {
          violation: "41 - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat Hier prüfen **",
        },
        {
          violation: "51 - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat Hier prüfen **",
        },
        {
          violation: "61 - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate Hier prüfen **",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate Hier prüfen **",
        },
      ],
    },
    "Geschwindigkeitsüberschreitung in einer Baustelle (innerorts)": {
      header:
        "Bußgeldkatalog Geschwindigkeitsüberschreitung in einer Baustelle (innerorts)",
      fines: [
        {
          violation: "bis 10 km/h",
          fine: "30 €",
          points: "",
          suspension: "eher nicht",
        },
        {
          violation: "11 - 15 km/h",
          fine: "50 €",
          points: "",
          suspension: "Hier prüfen **",
        },
        {
          violation: "16 - 20 km/h",
          fine: "70 €",
          points: "",
          suspension: "Hier prüfen **",
        },
        {
          violation: "21 - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "Hier prüfen **",
        },
        {
          violation: "26 - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "(1 Monat)* Hier prüfen **",
        },
        {
          violation: "31 - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat Hier prüfen **",
        },
        {
          violation: "41 - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat Hier prüfen **",
        },
        {
          violation: "51 - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate Hier prüfen **",
        },
        {
          violation: "61 - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate Hier prüfen **",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate Hier prüfen **",
        },
      ],
    },
    "Baustelle für Baustellen-Verantwortliche": {
      header: "Bußgeldkatalog Baustelle für Baustellen-Verantwortliche",
      fines: [
        {
          violation:
            "Baustelle besitzt vor Beginn der Arbeiten keine gesetzliche Beschilderung/Regelung der zuständigen Behörde",
          fine: "75 Euro",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Anordnung der zuständigen Behörde für die richtige Beschilderung bzw. Regelung der Baustelle nicht befolgt",
          fine: "75 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "Lichtzeichenanlage der Baustelle nicht richtig bedient",
          fine: "75 Euro",
          points: "",
          suspension: "",
        },
      ],
    },
    "Parken & Halten auf dem Gehweg": {
      header: "Parken & Halten auf dem Gehweg",
      fines: [
        {
          violation: "Sie haben rechtswidrig auf dem Gehweg gehalten",
          fine: "50 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden behindert",
          fine: "55 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden gefährdet",
          fine: "70 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und es kam zu einer Sachbeschädigung",
          fine: "90 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "Sie haben rechtswidrig auf dem Gehweg geparkt",
          fine: "55 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden behindert",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden gefährdet",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und es kam zu einer Sachbeschädigung",
          fine: "100 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde und dabei jemanden behindert",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie haben bei erlaubtem Gehwegparken nicht den rechten Gehweg zum Parken genutzt",
          fine: "55 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden behindert",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden gefährdet",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und es kam zu einer Sachbeschädigung",
          fine: "100 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde und dabei jemanden behindert",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie haben bei erlaubtem Gehwegparken in einer Einbahnstraße nicht den Gehweg zum Parken genutzt",
          fine: "55 Euro",
          points: "",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden behindert",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und dabei jemanden gefährdet",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... und es kam zu einer Sachbeschädigung",
          fine: "100 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde",
          fine: "70 Euro",
          points: "1",
          suspension: "",
        },
        {
          violation: "... für über eine Stunde und dabei jemanden behindert",
          fine: "80 Euro",
          points: "1",
          suspension: "",
        },
      ],
    },

    "Fahren auf dem Gehweg": {
      header: "Aktueller Bußgeldkatalog zum Fahren auf dem Gehweg",
      fines: [
        {
          violation: "verbotswidrig Gehweg mit Kfz befahren",
          fine: "55 ",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Unfallfolge",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "mit dem Fahrrad einen Gehweg verbotswidrig befahren",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Unfallfolge",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "mit dem E-Scooter eine nicht zulässige Verkehrsfläche befahren",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Unfallfolge",
          fine: "30 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Busspur: {
      header: "Busspur: Verstöße gegen die Verkehrsregeln",
      fines: [
        {
          violation: "Verbotswidrig auf Busspur fahren",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung des Linienverkehrs",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Abbiegen, ohne einen in entgegenkommender/gleicher Richtung geradeaus weiterfahrenden Benutzer der Busspur durchfahren zu lassen",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "140 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "... mit daraus resultierendem Unfall",
          fine: "170 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "Halten auf der Busspur",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Parken auf der Busspur",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... über 3 Stunden",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... über 3 Stunden mit Behinderung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... über 3 Stunden mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Einbahnstraße: {
      header: "Einbahnstraße: Verstöße gegen die Verkehrsregeln",
      fines: [
        {
          violation:
            "Einbahnstraße in falscher Richtung befahren (Zeichen 267)",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Einbahnstraße in falscher Richtung befahren mit Sachbeschädigung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "In Einbahnstraße entgegen der Fahrtrichtung geparkt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Einbahnstraße mit dem Rad in falscher Richtung befahren",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Einbahnstraße mit dem Rad in falscher Richtung befahren mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Einbahnstraße mit dem Rad in falscher Richtung befahren mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Einbahnstraße mit dem Rad in falscher Richtung befahren mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Fahrbahnverengung: {
      header:
        "Fahrbahnverengung: Verkehrsverstöße im Zusammenhang mit Hindernissen und Fahrstreifenwechsel",
      fines: [
        {
          violation:
            "An einem Hindernis links vorbeigefahren, ohne dem entgegenkommenden Verkehr Vorfahrt zu gewähren",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "An einem Hindernis links vorbeigefahren, ohne dem entgegenkommenden Verkehr Vorfahrt zu gewähren und dabei Gefährdung verursacht",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "An einem Hindernis links vorbeigefahren, ohne dem entgegenkommenden Verkehr Vorfahrt zu gewähren und dabei Unfall verursacht",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Vor dem Hindernis ausgeschert, ohne zu blinken",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Beim Wiedereinordnen nicht geblinkt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Fahrstreifenbegrenzung und Fahrbahnbegrenzung": {
      header: "Fahrstreifenbegrenzung und Fahrbahnbegrenzung",
      fines: [
        {
          violation: "Verbotswidrig über die Fahrstreifenbegrenzung gefahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren und Behinderung des Gegenverkehrs nicht ausgeschlossen",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren und Gefährdung anderer Verkehrsteilnehmer",
          fine: "250 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren und Verursachung eines Unfalls",
          fine: "300 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren, obwohl die Verkehrslage unklar war",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren und Gefährdung anderer Verkehrsteilnehmer",
          fine: "250 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Überholen gefahren und Verursachung eines Unfalls",
          fine: "300 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Linksabbiegen gefahren",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Linksabbiegen gefahren und Gefährdung anderer Verkehrsteilnehmer",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Linksabbiegen gefahren und Verursachung eines Unfalls",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Wenden gefahren",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig über die Fahrstreifenbegrenzung beim Wenden gefahren und Gefährdung anderer Verkehrsteilnehmer",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig links von einer Fahrbahnbegrenzung gehalten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig links von einer Fahrbahnbegrenzung gehalten und Behinderung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verbotswidrig links von einer Fahrbahnbegrenzung geparkt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig links von einer Fahrbahnbegrenzung geparkt und Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig links von einer Fahrbahnbegrenzung geparkt länger als eine Stunde",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig links von einer Fahrbahnbegrenzung geparkt länger als eine Stunde mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig geparkt mit weniger als drei Metern Abstand zur Fahrstreifenbegrenzung",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig geparkt mit weniger als drei Metern Abstand zur Fahrstreifenbegrenzung und Behinderung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig geparkt länger als drei Stunden mit weniger als drei Metern Abstand zur Fahrstreifenbegrenzung",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig geparkt länger als drei Stunden mit weniger als drei Metern Abstand zur Fahrstreifenbegrenzung und Behinderung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Fehlverhalten am Fußgängerüberweg": {
      header: "Fehlverhalten am Fußgängerüberweg",
      fines: [
        {
          violation:
            "Nicht mit mäßiger Geschwindigkeit an einen Fußgängerüberweg herangefahren, an dem ein Fußgänger passieren wollte",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht das Überqueren des Fußgängerüberwegs ermöglicht, obwohl ein Fußgänger passieren wollte",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Verursachung eines Unfalls",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Bei stockendem Verkehr auf den Fußgängerüberweg fahren",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Am Fußgängerüberweg ein Fahrzeug überholen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Verursachung eines Unfalls",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Halten auf dem Fußgängerüberweg",
          fine: "mind. 20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Parken auf dem Fußgängerüberweg",
          fine: "mind. 40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Parken im Abstand von weniger als 5 m vor dem Fußgängerüberweg",
          fine: "mind. 25 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Führerschein: {
      header: "Führerschein: Verstoß gegen verschiedene Bestimmungen",
      fines: [
        {
          violation: "Führerschein nicht mitgeführt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Führerschein nicht mitgeführt beim Fahren eines Mofas",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verlust des Führerscheins nicht unverzüglich angezeigt und kein Ersatzdokument ausstellen lassen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Führerschein nach Verlust und Wiederfinden nicht unverzüglich abgeliefert",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Führerschein nach Entziehung der Fahrerlaubnis nicht unverzüglich abgegeben",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Führerschein nicht zur Eintragung von Beschränkungen oder Auflagen abgegeben",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kein erforderlicher Führerschein bei der Fahrgastbeförderung mitgeführt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kein erforderlicher Führerschein bei der Fahrgastbeförderung auf Verlangen ausgehändigt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Führerschein zur Fahrgastbeförderung nach Entziehung nicht abgegeben",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Allgemeiner Führerschein nach Entziehung nicht abgegeben",
          fine: "25 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Fußgängerzone: {
      header: "Fußgängerzone: Verstöße gegen die Verkehrsregeln",
      fines: [
        {
          violation:
            "Sie sind aus einer Fußgängerzone herausgefahren und haben dabei jemanden gefährdet",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie sind aus einer Fußgängerzone herausgefahren und haben dabei einen Unfall verursacht",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie sind aus einer Fußgängerzone herausgefahren und eingebogen, ohne zu blinken",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Sie haben verbotswidrig in einer Fußgängerzone geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben verbotswidrig in einer Fußgängerzone geparkt und andere dadurch behindert",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben länger als 3 Stunden in einer Fußgängerzone geparkt",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben verbotswidrig mit einem Fahrzeug über 3,5 Tonnen zulässiger Gesamtmasse eine Fußgängerzone benutzt",
          fine: "75 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben verbotswidrig mit einem Kfz bis 3,5 Tonnen zulässiger Gesamtmasse eine Fußgängerzone benutzt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben mit dem Fahrrad eine Fußgängerzone befahren, ohne eine Erlaubnis hierfür zu besitzen",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben mit dem Fahrrad eine Fußgängerzone befahren und andere dadurch behindert",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben mit dem Fahrrad eine Fußgängerzone befahren und andere dadurch gefährdet",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben mit dem Fahrrad eine Fußgängerzone befahren und dadurch einen Unfall verursacht",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben sich in einer Fußgängerzone, in der Fahrzeugverkehr zugelassen ist, nicht an die Schrittgeschwindigkeit gehalten",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben als Kraftfahrzeugführer in einer Fußgängerzone, in der Fahrzeugverkehr zugelassen ist, einen Fußgänger gefährdet",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie haben als Radfahrer in einer Fußgängerzone, in der Fahrzeugverkehr zugelassen ist, einen Fußgänger gefährdet",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie haben als Kraftfahrzeugführer in einer Fußgängerzone, in der Fahrzeugverkehr nicht zugelassen ist, einen Fußgänger gefährdet",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie haben als Radfahrer in einer Fußgängerzone, in der Fahrzeugverkehr nicht zugelassen ist, einen Fußgänger gefährdet",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Geisterfahrer: {
      header: "Geisterfahrer: Befahren der falschen Seite im Straßenverkehr",
      fines: [
        {
          violation: "Geisterfahrt",
          fine: "Freiheitsstrafe bis zu 5 Jahren oder Geldstrafe",
          points: "",
          suspension: "",
        },
      ],
    },
    "Gelbe Ampel": {
      header: "gelbe Ampel: Verstoß gegen das Verkehrslichtsignal",
      fines: [
        {
          violation:
            "Obwohl eine gefahrlose Bremsung möglich gewesen wäre, wurde das Gelblicht nicht beachtet",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Missachtung des Rotlichts in Verbindung mit dem Gelblicht",
          fine: "15 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Geschlossener Verband": {
      header:
        "Geschlossener Verband: Verstöße gegen die vorgeschriebenen Regeln",
      fines: [
        {
          violation:
            "Als Verantwortlicher den Verband nicht zur Nutzung eines Gehwegs veranlasst",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Geschlossenen sich bewegenden Verband unterbrochen",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Geschlossenen sich bewegenden Verband unterbrochen inkl. Unfall",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Als Verantwortlicher nicht sichergestellt, dass die vorgeschriebenen Regeln eingehalten werden",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "An einem nicht genehmigten geschlossenen Verband teilgenommen",
          fine: "25 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Geschwindigkeitsindex und Tragfähigkeitsindex": {
      header: "Bußgeldkatalog Geschwindigkeitsindex und Tragfähigkeitsindex",
      fines: [
        {
          violation:
            "Laut Fahrzeugschein notwendigen Geschwindigkeitsindex oder Tragfähigkeitsindex unterschritten, sodass die Betriebserlaubnis erloschen ist",
          fine: "50 €",
          points: "",
          suspension:
            "Sofortige Stilllegung des Fahrzeugs, kostepflichtige Vorführung bei einem Sachverständigen, Erlöschen des Versicherungsschutzes",
        },
        {
          violation:
            "Laut Fahrzeugschein notwendigen Geschwindigkeitsindex oder Tragfähigkeitsindex unterschritten, sodass die Betriebserlaubnis erloschen ist mit Gefährdung der Verkehrssicherheit",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "M+S-Reifen mit geringeren Geschwindigkeitsindex aufgezogen, ohne eine entsprechende Plakette im Sichtfeld des Fahrers anzubringen",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "M+S-Reifen mit geringeren Geschwindigkeitsindex aufgezogen, ohne eine entsprechende Plakette im Sichtfeld des Fahrers anzubringen mit Überschreitung der Höchstgeschwindigkeit gemäß Geschwindigkeitsindex",
          fine: "25 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Pkw: Überschreitung der Höchstgeschwindigkeit außerorts": {
      header: "Pkw: Überschreitung der Höchstgeschwindigkeit außerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "20 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Pkw: Überschreitung der Höchstgeschwindigkeit innerorts": {
      header: "Pkw: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "70 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Pkw mit Anhänger: Überschreitung der Höchstgeschwindigkeit außerorts": {
      header:
        "Pkw mit Anhänger: Überschreitung der Höchstgeschwindigkeit außerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        {
          violation: "16 - 20 km/h",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "175 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "255 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Pkw mit Anhänger: Überschreitung der Höchstgeschwindigkeit innerorts": {
      header:
        "Pkw mit Anhänger: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "16 - 20 km/h",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "175 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "235 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "340 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "51 - 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Lkw über 3,5 t: Überschreitung der Höchstgeschwindigkeit außerorts": {
      header:
        "Lkw über 3,5 t: Überschreitung der Höchstgeschwindigkeit außerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        {
          violation: "11 bis 15 km/h",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "16 bis 20 km/h",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 bis 25 km/h",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 bis 30 km/h",
          fine: "175 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 bis 40 km/h",
          fine: "255 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 bis 50 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 bis 60 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Lkw über 3,5 t: Überschreitung der Höchstgeschwindigkeit innerorts": {
      header:
        "Lkw über 3,5 t: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        { violation: "bis 10 km/h", fine: "40 €", points: "", suspension: "" },
        {
          violation: "11 bis 15 km/h",
          fine: "60 €",
          points: "",
          suspension: "",
        },
        {
          violation: "16 bis 20 km/h",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "21 bis 25 km/h",
          fine: "175 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 bis 30 km/h",
          fine: "235 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "31 bis 40 km/h",
          fine: "340 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 bis 50 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "51 bis 60 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 60 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "beim Rechts­abbiegen innerorts nicht Schrittge­schwindigkeit gefahren": {
      header:
        "beim Rechts­abbiegen innerorts nicht Schrittge­schwindigkeit gefahren",
      fines: [{ violation: "", fine: "70 €", points: "1", suspension: "" }],
    },
    "Ampelanlage mit Grünpfeil": {
      header: "Bußgeldkatalog Ampelanlage mit Grünpfeil",
      fines: [
        {
          violation:
            "Am Grün­pfeil bei roter Ampel nicht den rechten Fahr­streifen zum Ein­biegen benutzt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "...mit Behin­derung auf der durch den Grün­pfeil freige­gebenen Richtung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Am Grün­pfeil bei roter Ampel abge­bogen, ohne vorher anzu­halten",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Gefährdung des Kreu­zungsver­kehrs beim Über­fahren der Ampel auf­grund des Grün­pfeils",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Unfall",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Abbie­gen an einem grü­nen Pfeil Fahrrad­fahrer oder Fuß­gänger behindert",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Unfall",
          fine: "180 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    Haltelinienverstoß: {
      header: "Haltelinienverstoß: Nicht an der Haltelinie gehalten",
      fines: [
        {
          violation: "Nicht an der Haltelinie gehalten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Bei roter Ampel nicht an der Haltelinie gehalten und dabei andere gefährdet",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Bei roter Ampel nicht an der Haltelinie gehalten und dabei einen Unfall verursacht",
          fine: "85 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Falsches Halten": {
      header: "Falsches Halten: Verstoß gegen die Park- und Halteverbote",
      fines: [
        {
          violation: "unzulässiges Halten an bestimmten Orten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten an bestimmten Orten",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässiges Halten auf einer Autobahn oder Kraftfahrstraße",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässiges Halten mit Behinderung von Rettungsfahrzeugen im Einsatz",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten in zweiter Reihe",
          fine: "55 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Gefährdung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Sachbeschädigung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "verbotswidriges Halten auf einem Gehweg, Radweg oder auf einer Fahrradstraße",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Gefährdung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Sachbeschädigung",
          fine: "90 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässiges Halten auf Schutzstreifen für den Radverkehr",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Gefährdung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Sachbeschädigung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten auf Bussonderstreifen",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Halten im Fahr­raum von Schienen­fahrzeugen",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Halten im Bereich eines Taxistandes",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "nicht platzsparendes Halten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässiges Halten in einer Not­halte- oder Pannenbucht unberechtigt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Falsches Parken": {
      header: "Falsches Parken: Verstoß gegen die Park- und Halteverbote",
      fines: [
        {
          violation: "an bestimmten Orten geparkt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden mit Behinderung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "an bestimmten Orten geparkt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "an bestimmten Orten geparkt",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Behinderung",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "an bestimmten Orten geparkt",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Behinderung",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässiges Parken mit Behinderung von Rettungsfahrzeugen",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "an bestimmten Orten geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Behinderung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken auf einem Gehweg oder Radweg",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 1 Stunde mit Behinderung",
          fine: "80 €",
          points: "",
        },
        {
          violation:
            "ohne Parkscheibe oder Parkschein geparkt bzw. Über­schreiten der Parkdauer",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "ohne Parkscheibe oder Parkschein geparkt bzw. Über­schreiten der Parkdauer",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "ohne Parkscheibe oder Parkschein geparkt bzw. Über­schreiten der Parkdauer",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "ohne Parkscheibe oder Parkschein geparkt bzw. Über­schreiten der Parkdauer",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "ohne Parkscheibe oder Parkschein geparkt bzw. Über­schreiten der Parkdauer",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation: "in Fußgängerbereichen oder anderen Verbotszonen geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "im Fahr­raum von Schienen­fahrzeugen geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "auf Autobahnen oder Kraftfahrstraßen geparkt",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Gefährdung",
          fine: "85 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Sachbeschädigung",
          fine: "105 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "unzulässig auf einem Bussonderfahrstreifen oder weniger als 15 Meter von einem Haltestellenschild entfernt geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden mit Behinderung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässiges Parken über 3 Stunden mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässig auf einem Schwerbehinderten-Parkplatz geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "unzulässig auf einem Parkplatz für E-Fahrzeuge geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "unzulässig auf einem Parkplatz für Carsharing-Fahrzeuge geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Sonstige Parkverstöße": {
      header: "Sonstige Parkverstöße: Verstöße gegen spezifische Parkregeln",
      fines: [
        {
          violation:
            "Beim Ein- oder Aussteigen andere Verkehrsteilnehmer gefährdet (Dooring)",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Beim Verlassen des Fahrzeugs keine Vorsichtsmaßnahmen getroffen",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Nicht platzsparend geparkt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Parklücke einem vorrangig Berechtigten weggenommen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Abfahrtsweg eines anderen Fahrzeugs zugeparkt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Unberechtigt in einer Nothalte- oder Pannenbucht geparkt",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Auf Sperrflächen geparkt",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Länger als zwei Wochen Anhänger ohne Zugfahrzeug geparkt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "In einem geschützten Bereich während nicht zugelassener Zeiten mit einem Fahrzeug über 7,5 t oder einem Anhänger über 2 t geparkt",
          fine: "30 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Fahrzeugpapiere, Hauptuntersuchung, Technik": {
      header:
        "Fahrzeugpapiere, Hauptuntersuchung, Technik: Verstöße gegen Vorschriften",
      fines: [
        {
          violation: "HU überzogen um folgenden Zeitraum (2-4 Monate)",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "HU überzogen um folgenden Zeitraum (4-8 Monate)",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "HU überzogen über 8 Monate",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "AU überzogen um folgenden Zeitraum (2-4 Monate)",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "AU überzogen um folgenden Zeitraum (4-8 Monate)",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "AU überzogen über 8 Monate",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "HU überzogen um folgenden Zeitraum (für Fahrzeuge mit Sicherheitsprüfung)",
          fine: "bis 2 Monate",
          points: "",
          suspension: "",
        },
        {
          violation:
            "HU überzogen um folgenden Zeitraum (für Fahrzeuge mit Sicherheitsprüfung)",
          fine: "2-4 Monate",
          points: "",
          suspension: "",
        },
        {
          violation:
            "HU überzogen um folgenden Zeitraum (für Fahrzeuge mit Sicherheitsprüfung)",
          fine: "4-8 Monate",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "HU überzogen über 8 Monate (für Fahrzeuge mit Sicherheitsprüfung)",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Meldepflichtige Änderungen nicht mitgeteilt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Mit unzulässiger Mischbereifung gefahren",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Als Halter die Fahrt angeordnet oder zugelassen",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Mit Reifen ohne Mindestprofiltiefe von 1,6 mm gefahren",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Als Halter die Fahrt angeordnet oder zugelassen",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Verkehrsunsiheres Fahrzeug betrieben",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Fahrzeug betrieben ohne Betriebserlaubnis",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Fahrzeug mit defektem Auspuff betrieben",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug betrieben und dabei gegen Vorschriften über Lichter und Leuchten verstoßen",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug mit Reifen betrieben, die nicht den Wetterverhältnissen angepasst sind (Winterreifen)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Zusätzlich mit Behinderung",
          fine: "80 ",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Handy am Steuer": {
      header: "Handy am Steuer: Nutzung des Handys während der Fahrt",
      fines: [
        {
          violation: "Als Kraftfahrer das Handy am Steuer genutzt",
          fine: "128,50 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Als Kraftfahrer das Handy am Steuer genutzt mit Gefährdung",
          fine: "178,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Als Kraftfahrer das Handy am Steuer genutzt mit Sachbeschädigung",
          fine: "228,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "Beim Fahrradfahren das Handy genutzt",
          fine: "83,50 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Illegale Autorennen": {
      header: "Illegale Autorennen",
      fines: [
        {
          violation:
            "Veranstaltung oder Teilnahme an einem verbotenen Autorennen",
          fine: "",
          points: "3",
          suspension:
            "Freiheitsstrafe bis zu 2 Jahren oder Geldstrafe; Fahrerlaubnisentzug",
        },
        {
          violation:
            "Veranstaltung oder Teilnahme an einem verbotenen Autorennen mit Gefährdung",
          fine: "",
          points: "3",
          suspension:
            "Freiheitsstrafe bis zu 5 Jahren oder Geldstrafe; Fahrerlaubnisentzug",
        },
        {
          violation:
            "Veranstaltung oder Teilnahme an einem verbotenen Autorennen mit Personenschaden",
          fine: "",
          points: "3",
          suspension:
            "Freiheitsstrafe von 1 bis zu 10 Jahren; in minder schweren Fällen von 6 Monaten bis zu 5 Jahren; Fahrerlaubnisentzug",
        },
      ],
    },
    "Kfz-Zulassung, -Ummeldung und -Abmeldung": {
      header:
        "Kfz-Zulassung, -Ummeldung und -Abmeldung: Verstoß gegen die Zulassungsvorschriften",
      fines: [
        {
          violation:
            "Das nicht zugelassene Fahrzeug stand an einer Stelle, wodurch der Verkehr gefährdet oder erschwert wurde",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Das Fahrzeug wurde gefahren, obwohl es nicht zugelassen war",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Die meldepflichtige Änderung wurde nicht unverzüglich der zuständigen Zulassungsbehörde mitgeteilt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Keine unverzügliche Beantragung auf die Zuteilung eines neuen Kennzeichens trotz Standortwechsels",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Das Fahrzeug nahm trotz Betriebsverbot am Straßenverkehr teil",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Keine unverzügliche Meldung bei der zuständigen Zulassungsbehörde aufgrund eines Wechsels des Fahrzeughalters",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Die Zulassungsbescheinigung Teil 1 wurde beim Fahren des Fahrzeugs vergessen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Der Fahrer des Fahrzeugs händigte auf Verlangen einer zuständigen Person nicht die Zulassungsbescheinigung Teil 1 aus",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Das zulassungsfreie Fahrzeug wurde in Betrieb gesetzt, obwohl es keine erforderliche EG-Typgenehmigung bzw. Einzelgenehmigung besitzt",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Das zulassungsfreie Fahrzeug wurde auf einer öffentlichen Straße ohne vorgeschriebenes Kennzeichen in Betrieb gesetzt",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Das zulassungsfreie Fahrzeug wurde auf einer öffentlichen Straße ohne gültiges Versicherungskennzeichen in Betrieb gesetzt",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Das zulassungsfreie Fahrzeug wurde in Betrieb gesetzt, ohne die erforderlichen Bescheinigungen mitzuführen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Der Fahrer des zulassungsfreien Fahrzeugs händigte auf Verlangen einer zuständigen Person nicht die erforderlichen Bescheinigungen aus",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Kraftfahrstraße: Geschwindigkeitsüberschreitung außerorts (Pkw)": {
      header:
        "Geschwindigkeitsüberschreitung auf der Kraftfahrstraße außerorts (Pkw)",
      fines: [
        { violation: "bis 10 km/h", fine: "20 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "40 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "60 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Kraftfahrstraße: Geschwindigkeitsüberschreitung außerorts (Lkw, Pkw mit Anhänger)":
      {
        header:
          "Geschwindigkeitsüberschreitung auf der Kraftfahrstraße außerorts (Lkw, Pkw mit Anhänger)",
        fines: [
          {
            violation: "bis 10 km/h",
            fine: "30 €",
            points: "",
            suspension: "",
          },
          {
            violation: "11 - 15 km/h",
            fine: "50 €",
            points: "",
            suspension: "",
          },
          {
            violation: "16 - 20 km/h",
            fine: "140 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 - 25 km/h",
            fine: "150 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 - 30 km/h",
            fine: "175 €",
            points: "1",
            suspension: "1 Monat",
          },
          {
            violation: "31 - 40 km/h",
            fine: "255 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 - 50 km/h",
            fine: "480 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "51 - 60 km/h",
            fine: "600 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "700 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Kraftfahrstraße: Geschwindigkeitsüberschreitung innerorts (Lkw, Pkw mit Anhänger)":
      {
        header:
          "Geschwindigkeitsüberschreitung auf der Kraftfahrstraße innerorts (Lkw, Pkw mit Anhänger)",
        fines: [
          {
            violation: "bis 10 km/h",
            fine: "40 €",
            points: "",
            suspension: "",
          },
          {
            violation: "11 - 15 km/h",
            fine: "60 €",
            points: "",
            suspension: "",
          },
          {
            violation: "16 - 20 km/h",
            fine: "160 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 - 25 km/h",
            fine: "175 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 - 30 km/h",
            fine: "235 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "31 - 40 km/h",
            fine: "340 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 - 50 km/h",
            fine: "560 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "51 - 60 km/h",
            fine: "700 €",
            points: "2",
            suspension: "3 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "800 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Kraftfahrstraße: Abstandsverstoß": {
      header: "Abstandsverstoß auf der Kraftfahrstraße",
      fines: [
        {
          violation: "Abstandsverstoß bei weniger als 80 km/h",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Abstandsverstoß mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Abstandsverstoß mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 80 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "160 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 100 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h - Abstand weniger als 5/10 des halben Tachowertes",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h - Abstand weniger als 4/10 des halben Tachowertes",
          fine: "180 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h - Abstand weniger als 3/10 des halben Tachowertes",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h - Abstand weniger als 2/10 des halben Tachowertes",
          fine: "320 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation:
            "Abstandsverstoß mit mehr als 130 km/h - Abstand weniger als 1/10 des halben Tachowertes",
          fine: "400 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Kraftfahrstraße: Wenden, Rückwärtsfahren und Fahren entgegen der Fahrtrichtung":
      {
        header:
          "Wenden, Rückwärtsfahren und Fahren entgegen der Fahrtrichtung auf der Kraftfahrstraße",
        fines: [
          {
            violation:
              "Rücksichtsloses Wenden, Rückwärtfahren, Fahren entgegen der Fahrrichtung",
            fine: "variiert",
            points: "3",
            suspension:
              "Freiheitsstrafe bis zu 5 Jahren, Entzug der Fahrerlaubnis",
          },
          {
            violation:
              "Rückwärts & entgegen der Fahrtrichtung fahren in Ein- oder Ausfahrt",
            fine: "75 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Rückwärts & entgegen der Fahrtrichtung fahren in Ein- oder Ausfahrt mit Gefährdung",
            fine: "90 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Rückwärts & entgegen der Fahrtrichtung fahren in Ein- oder Ausfahrt mit Verursachung eines Unfalls",
            fine: "110 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden in Ein- oder Ausfahrt",
            fine: "75 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden in Ein- oder Ausfahrt mit Gefährdung",
            fine: "90 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Wenden in Ein- oder Ausfahrt mit Verursachung eines Unfalls",
            fine: "110 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden auf Nebenstraße oder Seitenstreifen",
            fine: "130 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Wenden auf Nebenstraße oder Seitenstreifen mit Gefährdung",
            fine: "160 €",
            points: "1",
            suspension: "",
          },
          {
            violation:
              "Wenden auf Nebenstraße oder Seitenstreifen mit Verursachung eines Unfalls",
            fine: "195 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "Wenden auf der durchgehenden Fahrbahn",
            fine: "200 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "Wenden auf der durchgehenden Fahrbahn mit Gefährdung",
            fine: "240 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation:
              "Wenden auf der durchgehenden Fahrbahn mit Verursachung eines Unfalls",
            fine: "290 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "Wenden im Tunnel",
            fine: "60 €",
            points: "1",
            suspension: "",
          },
        ],
      },
    "Kraftfahrstraße: Halten und Parken": {
      header: "Halten und Parken auf der Kraftfahrstraße",
      fines: [
        {
          violation:
            "Unzulässiges Halten auf der Kraftfahrstraße bzw. Autobahn",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässiges Halten auf der Kraftfahrstraße bzw. Autobahn mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässiges Parken auf der Kraftfahrstraße bzw. Autobahn",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Unzulässiges Parken auf der Kraftfahrstraße bzw. Autobahn mit Gefährdung",
          fine: "85 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Unzulässiges Parken auf der Kraftfahrstraße bzw. Autobahn mit Unfall",
          fine: "105 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Weitere Verstöße auf der Kraftfahrstraße": {
      header: "Weitere Verstöße auf der Kraftfahrstraße",
      fines: [
        {
          violation: "Seitenstreifen zum schnelleren Vorwärtskommen benutzen",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht möglichst weit rechts fahren, obwohl dies möglich gewesen wäre, mit Gefährdung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Autobahn / Kraftfahrstraße mit Fahrzeug befahren, dessen Höchstgeschwindigkeit 60 km/h oder weniger beträgt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Autobahn / Kraftfahrstraße mit Fahrzeug befahren, dessen zulässige Höhe zusammen mit der Ladung überschritten ist (Gesamthöhe nicht mehr als 4,20 m)",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Autobahn oder Kraftfahrstraße mit einem Fahrzeug benutzt, dessen Höhe zusammen mit der Ladung mehr als 4,20 m beträgt",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Ein- oder Ausfahren an unzulässigen Stellen",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Ein- oder Ausfahren an unzulässigen Stellen mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Ein- oder Ausfahren an unzulässigen Stellen mit Verursachung eines Unfalls",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Einfahren Vorfahrt auf der durchgehenden Fahrbahn nicht beachten",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Einfahren Vorfahrt auf der durchgehenden Fahrbahn nicht beachten mit Gefährdung anderer Verkehrsteilnehmer",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Beim Einfahren Vorfahrt auf der durchgehenden Fahrbahn nicht beachten mit Verursachung eines Unfalls",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Als Fußgänger Autobahn betreten oder Kraftfahrstraße an dafür nicht vorgesehener Stelle betreten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Kreisverkehr: {
      header: "Kreisverkehr: Verstöße gegen die Verkehrsregeln im Kreisverkehr",
      fines: [
        {
          violation:
            "Bei Einfahrt in den Kreisverkehr geblinkt bzw. bei Ausfahrt nicht geblinkt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Mittelinsel verbotswidrig überfahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Mittelinsel verbotswidrig überfahren mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Beim berechtigten Überfahren der Mittelinsel einen anderen Verkehrsteilnehmer gefährdet",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verbotswidrig innerhalb des Kreisverkehrs gehalten",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig innerhalb des Kreisverkehrs gehalten mit Behinderung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verbotswidrig innerhalb des Kreisverkehrs geparkt",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig innerhalb des Kreisverkehrs geparkt mit Behinderung",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig innerhalb des Kreisverkehrs geparkt länger als eine Stunde",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig innerhalb des Kreisverkehrs geparkt länger als eine Stunde mit Behinderung",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Entgegen der Fahrtrichtung im Kreisverkehr gefahren",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Entgegen der Fahrtrichtung im Kreisverkehr gefahren als Fahrradfahrer",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Entgegen der Fahrtrichtung im Kreisverkehr gefahren mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Entgegen der Fahrtrichtung im Kreisverkehr gefahren mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Entgegen der Fahrtrichtung im Kreisverkehr gefahren mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Ladungssicherung: {
      header:
        "Ladungssicherung: Verstöße gegen die Vorschriften zur Ladungssicherung",
      fines: [
        {
          violation: "Ladung nicht gegen vermeidbaren Lärm gesichert",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Ladung höher als zulässig",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Ladung breiter als zulässig",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Ladung ragte unzulässig nach vorne",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Ladung ohne vorgeschriebene Sicherungsmitteln befördert",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Schlecht erkennbare Gegenstände ragten seitlich aus der Ladung hinaus",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Ladung nicht verkehrssicher verstaut",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Ladung nicht verkehrssicher verstaut - andere Verkehrsteilnehmer wurden gefährdet",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Ladung nicht verkehrssicher verstaut - es kam zum Unfall",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf der Autobahn/Kraftfahrtstraße mit einer Ladung über 4,20 m Höhe gefahren",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht vorschriftsmäßige Ladung - die Verkehrssicherheit litt erheblich",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht vorschriftsmäßige Ladung - die Verkehrssicherheit litt erheblich und es kam zum Unfall",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie ließen die Inbetriebnahme eines Kfz zu, dessen Ladung die Verkehrssicherheit erheblich beeinträchtigte",
          fine: "135 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie ließen die Inbetriebnahme eines Kfz zu, dessen Ladung die Verkehrssicherheit erheblich beeinträchtigte - andere Verkehrsteilnehmer wurden gefährdet",
          fine: "165 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie ließen die Inbetriebnahme eines Kfz zu, dessen Ladung die Verkehrssicherheit erheblich beeinträchtigte - es kam zum Unfall",
          fine: "200 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf der Autobahn/Kraftfahrtstraße mit einer Ladung, die nicht jederzeit weniger als 4 m Höhe betrug, gefahren und dadurch die automatische Höhenkontrolle ausgelöst. Der Fahrstreifen wurde gesperrt",
          fine: "160 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Auf der Autobahn/Kraftfahrtstraße mit einer Ladung über 4 m Höhe gefahren und dadurch die automatische Höhenkontrolle ausgelöst. Der Fahrstreifen wurde gesperrt",
          fine: "240 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Landstraße: Überschreitung der Höchstgeschwindigkeit außerorts (Pkw mit Anhänger)":
      {
        header:
          "Überschreitung der Höchstgeschwindigkeit außerorts (Pkw mit Anhänger)",
        fines: [
          {
            violation: "bis 10 km/h",
            fine: "58,50 €",
            points: "",
            suspension: "",
          },
          {
            violation: "11 - 15 km/h",
            fine: "78,50 €",
            points: "",
            suspension: "",
          },
          {
            violation: "16 - 20 km/h",
            fine: "168,50 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 - 25 km/h",
            fine: "178,50 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 - 30 km/h",
            fine: "203,50 €",
            points: "1",
            suspension: "1 Monat",
          },
          {
            violation: "31 - 40 km/h",
            fine: "283,50 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 - 50 km/h",
            fine: "508,50 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "51 - 60 km/h",
            fine: "633,50 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "738,50 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Landstraße: Überschreitung der Höchstgeschwindigkeit außerorts (Lkw über 3,5 t)":
      {
        header:
          "Überschreitung der Höchstgeschwindigkeit außerorts (Lkw über 3,5 t)",
        fines: [
          {
            violation: "bis 10 km/h",
            fine: "58,50 €",
            points: "",
            suspension: "",
          },
          {
            violation: "11 - 15 km/h",
            fine: "78,50 €",
            points: "",
            suspension: "",
          },
          {
            violation: "16 - 20 km/h",
            fine: "168,50 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 - 25 km/h",
            fine: "178,50 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 - 30 km/h",
            fine: "203,50 €",
            points: "1",
            suspension: "1 Monat",
          },
          {
            violation: "31 - 40 km/h",
            fine: "283,50 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 - 50 km/h",
            fine: "508,50 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "51 - 60 km/h",
            fine: "633,50 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "738,50 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Landstraße: Überholen": {
      header: "Bußgeldkatalog Überholen",
      fines: [
        {
          violation:
            "Nach dem Überholen beim Einordnen einen Überholten behindert",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Seitenabstand nicht eingehalten",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Geschwindigkeit erhöht beim Überholen",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nicht wesentlich schneller gewesen als der zu Überholende",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht wesentlich schneller gewesen als der zu Überholende mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Den nachfolgenden Verkehr gefährdet beim Überholen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen auf der rechten Spur innerorts",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur innerorts mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Überholen auf der rechten Spur außerorts",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur außerorts mit Gefährdung anderer",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur außerorts mit Sachbeschädigung",
          fine: "145 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen unter Nichtbeachten von Verkehrszeichen (VZ 276, 277)",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen am Fußgängerübergang",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen bei unklarer Verkehrslage",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot (VZ 276, 277, 295, 296, 297)",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot mit Gefährdung",
          fine: "250 €",
          points: "2",
          suspension:
            "1 Monat & je nach Tatbegehung Geldstrafe, Führerscheinentzug und Freiheitsstrafe bis 5 Jahre gemäß § 315c StGB möglich",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot mit Sachbeschädigung",
          fine: "300 €",
          points: "2",
          suspension: "1 Monat",
        },
      ],
    },
    "Motorrad: Überschreitung der Höchstgeschwindigkeit außerorts": {
      header: "Motorrad: Überschreitung der Höchstgeschwindigkeit außerorts",
      fines: [
        {
          violation: "bis 10 km/h",
          fine: "48,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "11 - 15 km/h",
          fine: "68,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "16 - 20 km/h",
          fine: "88,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "128,50 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "178,50 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "228,50 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "348,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "508,50 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "633,50 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "738,50 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Motorrad: Überschreitung der Höchstgeschwindigkeit innerorts": {
      header: "Motorrad: Überschreitung der Höchstgeschwindigkeit innerorts",
      fines: [
        {
          violation: "bis 10 km/h",
          fine: "58,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "11 - 15 km/h",
          fine: "78,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "16 - 20 km/h",
          fine: "98,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "143,50 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "208,50 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "288,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "428,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "591,50 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "738,50 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "843,50 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Motorrad: Beleuchtung": {
      header: "Motorrad: Beleuchtung",
      fines: [
        {
          violation:
            "Beleuchtung nicht vorschriftsmäßig benutzt oder nicht rechtzeitig abgeblendet oder Beleuchtungseinrichtungen in verdecktem oder beschmutztem Zustand benutzt",
          fine: "20-35 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "Nur mit Standlicht oder auf einer Straße mit durchgehender, ausreichender Beleuchtung mit Fernlicht gefahren",
          fine: "10-35 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "Kein Abblendlicht am Tage trotz Sichtbehinderung durch Nebel, Schneefall, Regen benutzt",
          fine: "25-60 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "Fahrtrichtungsanzeiger nicht wie vorgeschrieben benutzt (vergessen zu 'blinken')",
          fine: "10 €",
          points: "0",
          suspension: "",
        },
      ],
    },
    "Motorrad: Tuning": {
      header: "Motorrad: Tuning",
      fines: [
        {
          violation: "Lärm- und/oder Abgasbelästigung",
          fine: "10 €",
          points: "0",
          suspension: "",
        },
        {
          violation: "Fahrzeug ohne gültige Betriebserlaubnis geführt",
          fine: "50-90 €",
          points: "0",
          suspension: "",
        },
        {
          violation: "Verstoß gegen die zulässigen Fahrzeugabmessungen",
          fine: "60 €",
          points: "0",
          suspension: "",
        },
      ],
    },
    "Motorrad: Verkehrsverbot und Helmpflicht": {
      header: "Motorrad: Verkehrsverbot und Helmpflicht",
      fines: [
        {
          violation:
            "Vorschriftswidrig ein Verkehrsverbot (Zeichen 250, 251, 253, 254, 255, 260) nicht beachtet",
          fine: "20-25 €",
          points: "0",
          suspension: "",
        },
        {
          violation: "Während der Fahrt keinen geeigneten Helm getragen",
          fine: "15 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "Auf dem Motorrad ein Kind befördert, das keinen Helm trug",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Motorrad gefahren, obwohl die Betriebserlaubnis erloschen war",
          fine: "50 €",
          points: "0",
          suspension: "",
        },
        {
          violation:
            "...dabei die Verkehrssicherheit wesentlich beeinträchtigt",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    Personenbeförderung: {
      header:
        "Personenbeförderung: Verstöße gegen die Vorschriften zur Beförderung von Personen",
      fines: [
        {
          violation: "Person auf Kraftrad befördert, ohne speziellen Sitz",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Personen auf Zugmaschine befördert, ohne geeignete Sitzgelegenheit",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "in Wohnwagen hinter einem Kfz Personen befördert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Kind ohne vorschriftsgemäße Sicherung im Kfz mitgenommen",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "mehrere Kinder ohne vorschriftsgemäße Sicherung im Kfz mitgenommen",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kind ohne vorschriftsgemäße Sicherung im Kfz als Verantwortlicher/Kraftfahrzeugführer mitgenommen",
          fine: "60 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kinder ohne vorschriftsgemäße Sicherung im Kfz als Verantwortlicher/Kraftfahrzeugführer mitgenommen",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "auf der Ladefläche eines Fahrzeuges Personen befördert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "auf der Ladefläche eines Anhängers, der nicht in der Land- oder Forstwirtschaft eingesetzt wurde, Personen transportiert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "auf der Ladefläche eines Anhängers, der in der Land- oder Forstwirtschaft eingesetzt wurde, Personen ohne geeignete Sitzgelegenheit transportiert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "auf der Ladefläche eines Fahrzeuges stehen, ohne Erfordernis",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zulassen, dass Personen auf der Ladefläche eines Fahrzeuges stehen, ohne Erfordernis",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "auf einem einsitzigen Fahrrad eine Person befördert, die älter als 7 Jahre alt war",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "auf einem Fahrrad ein Kind transportieren, das die erforderlichen Sicherheitsvorrichtungen nicht aufweist",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "in einem Anhänger, den Sie hinter dem Fahrrad herziehen, mehr als zwei Kinder transportiert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "in einem Anhänger, den Sie hinter dem Fahrrad herziehen, ein Kind transportiert, das älter als 7 Jahre alt war",
          fine: "5 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Rechtsfahrgebot: {
      header: "Rechtsfahrgebot: Verstoß gegen das Rechtsfahrgebot",
      fines: [
        {
          violation: "Mit Gefährdung anderer bei Gegenverkehr",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer beim Überholtwerden",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer bei Unübersichtlichkeit",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer in einer Kurve",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer an einer Kuppe",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer beim zu engen Linksabbiegen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Gefährdung anderer beim zu weiten Rechtsabbiegen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mit Unfallfolge",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    Reifen: {
      header: "Reifen",
      fines: [
        {
          violation:
            "Fahrzeug mit nicht den Wetterverhältnissen angepassten Reifen (Winterreifen)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug mit nicht den Wetterverhältnissen angepassten Reifen (Winterreifen) mit Behinderung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug mit nicht den Wetterverhältnissen angepassten Reifen (Winterreifen) mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug mit nicht den Wetterverhältnissen angepassten Reifen (Winterreifen) mit Unfallfolge",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Kraftfahrzeug oder Anhänger in Betrieb genommen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Kraftfahrzeug oder Anhänger in Betrieb genommen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß mit Gefährdung",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Kraftfahrzeug oder Anhänger in Betrieb genommen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß mit Unfall",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Als Halter die Inbetriebnahme eines Kraftfahrzeugs oder Anhängers angeordnet oder zugelassen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Mofa in Betrieb genommen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Als Halter die Inbetriebnahme eines Mofas angeordnet oder zugelassen, dessen Reifen keine ausreichenden Profilrillen oder Einschnitte oder keine ausreichende Profil- oder Einschnitttiefe besaß",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Rote Ampel übersehen": {
      header: "Rote Ampel übersehen: Ampel bei Rot überfahren",
      fines: [
        {
          violation: "Ampel bei Rot überfahren",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Andere gefährdet",
          fine: "200 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "Sachschaden verursacht",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Ampel bei Rot überfahren (Rot bereits seit mehr als 1 Sekunde)",
          fine: "200 €",
          points: "2",
          suspension: "1 Monat*",
        },
        {
          violation: "Andere gefährdet",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat*",
        },
        {
          violation: "Sachschaden verursacht",
          fine: "360 €",
          points: "2",
          suspension: "1 Monat*",
        },
        {
          violation:
            "An einer Ampel mit grünem Pfeil nach rechts abgebogen, ohne vorher zu halten",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Fußgänger- oder Fahrradverkehr der freigegebenen Verkehrsrichtung behindert",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Andere gefährdet",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Sachschaden verursacht",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Vorfahrtsregeln in einer Sackgasse": {
      header: "Vorfahrtsregeln in einer Sackgasse",
      fines: [
        {
          violation: "Rechts-vor-links-Regel nicht beachtet - mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Rechts-vor-links-Regel nicht beachtet - mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Rechts-vor-links-Regel nicht beachtet - mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Wenden und Rückwärtsfahren in der Sackgasse": {
      header: "Bußgeldkatalog für Wenden und Rückwärtsfahren in der Sackgasse",
      fines: [
        {
          violation: "Auf der Straße wenden und dabei andere gefährden",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Auf der Straße wenden und dabei einen Unfall verursachen",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Beim Rückwärtsfahren andere gefährden",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Beim Rückwärtsfahren einen Unfall verursachen",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Schrittgeschwindigkeit innerorts (mit Pkw)": {
      header: "Überschreitung der Schrittgeschwindigkeit innerorts (mit Pkw)",
      fines: [
        {
          violation: "bis 10 km/h",
          fine: "58,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "11 - 15 km/h",
          fine: "78,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "16 - 20 km/h",
          fine: "98,50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "21 - 25 km/h",
          fine: "143,50 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "208,50 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "288,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "428,50 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "591,50 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "738,50 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "843,50 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    Seitenabstand: {
      header:
        "Seitenabstand: Unterschreitung des erforderlichen Abstands zu anderen Verkehrsteilnehmern",
      fines: [
        {
          violation: "Sie parkten nicht am rechten Fahrbahnrand",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie hielten beim Überholen keinen ausreichenden Seitenabstand zu anderen Verkehrsteilnehmern ein",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Sie gefährdeten als Fahrzeugführer ein Kind/einen Hilfsbedürftigen/einen älteren Menschen durch unzureichenden Seitenabstand beim Vorbeifahren oder Überholen",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Sie schädigten als Fahrzeugführer ein Kind/einen Hilfsbedürftigen/einen älteren Menschen durch unzureichenden Seitenabstand beim Vorbeifahren oder Überholen",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    Seitenstreifen: {
      header: "Seitenstreifen: Verbotswidrige Nutzung und Verstöße",
      fines: [
        {
          violation: "Verbotswidrig den Seitenstreifen befahren",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig den Seitenstreifen befahren inkl. Behinderung anderer Verkehrsteilnehmer",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig den Seitenstreifen befahren inkl. Gefährdung anderer Verkehrsteilnehmer",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig den Seitenstreifen befahren und Unfall verursacht",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Zum schnelleren Vorankommen den Seitenstreifen benutzt",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Zum schnelleren Vorankommen den Seitenstreifen benutzt und beim Auffahren auf den Fahrstreifen andere gefährdet",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Zum schnelleren Vorankommen den Seitenstreifen benutzt und beim Auffahren auf den Fahrstreifen in einen Unfall verwickelt",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verkehrtherum auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gefahren",
          fine: "130 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verkehrtherum auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gefahren und dabei andere gefährdet",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verkehrtherum auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gefahren und dabei in einen Unfall verwickelt",
          fine: "195 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Verbotswidrig auf dem linken Seitenstreifen gehalten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig auf dem linken Seitenstreifen gehalten inkl. Behinderung anderer",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig auf dem linken Seitenstreifen geparkt inkl. Behinderung anderer",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig länger als 1 Stunde auf dem linken Seitenstreifen geparkt inkl. Behinderung anderer",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gewendet",
          fine: "130 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gewendet inkl. Gefährdung anderer",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf dem Seitenstreifen einer Autobahn oder Kraftfahrstraße gewendet inkl. Unfall",
          fine: "195 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Rückwärts auf dem Seitenstreifen einer Autobahn oder Kraftfahrtstraße gefahren",
          fine: "130 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Rückwärts auf dem Seitenstreifen einer Autobahn oder Kraftfahrtstraße gefahren inkl. Gefährdung anderer",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Rückwärts auf dem Seitenstreifen einer Autobahn oder Kraftfahrtstraße gefahren inkl. Unfall",
          fine: "195 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf der Fahrbahn gegangen, obwohl ein Seitenstreifen existierte",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässigerweise mit Inline-Skates den Seitenstreifen benutzt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässigerweise mit Inline-Skates den Seitenstreifen benutzt inkl. Behinderung anderer",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässigerweise mit Inline-Skates den Seitenstreifen benutzt inkl. Gefährdung anderer",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unzulässigerweise mit Inline-Skates den Seitenstreifen benutzt inkl. Unfall",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Straßenverkehr: {
      header: "Straßenverkehr: Verstöße und Bußgelder",
      fines: [
        {
          violation:
            "Straße verbotswidrig befahren (Sonderfahrstreifen für Taxen und Busse des Linienverkehrs oder andere Verkehrsverbote)",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Behinderung der Busse des Linienverkehrs",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Im verkehrsberuhigten Bereich Fußgänger behindert",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Im Fußgängerbereich, in dem die Durchfahrt verboten ist, Fußgänger gefährdet",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Vorgeschriebene Fahrtrichtung nicht befolgt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Gehweg, Grünanlagen oder Seitenstreifen befahren",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Widerrechtliches Fahren in einer Umweltzone",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Befahren des Kreisverkehrs in falscher Richtung (Kfz)",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Trotz Durchfahrtsverbot in dem Verkehrsbereich geparkt",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... länger als 3 Stunden",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verkehrsbereich mit Motorrad oder Pkw befahren",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verkehrsbereich mit Pkw mit Anhänger, Wohnmobil oder Bus befahren (Kfz bis 3,5 t)",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verkehrsbereich mit Kfz (über 3,5 t) befahren",
          fine: "100 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verkehrsbereich mit dem Rad befahren",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Behinderung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Gefährdung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "... mit Sachbeschädigung",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Beschränkungen der Straßennutzung nach Gewicht, Länge und Breite missachtet",
          fine: "40 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Einbahnstraße in falscher Richtung befahren (Zeichen 267)",
          fine: "50 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "70 €",
          points: "",
          suspension: "",
        },
        {
          violation: "In Einbahnstraße entgegen der Fahrtrichtung geparkt",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Einbahnstraße mit dem Rad in falscher Richtung befahren",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    "Stau auf der Autobahn": {
      header: "Stau auf der Autobahn",
      fines: [
        {
          violation: "Fahrzeug verlassen, obwohl keine Notfallsituation vorlag",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Seitenstreifen zum schnelleren Vorankommen genutzt",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Seitenstreifen zum schnelleren Vorankommen genutzt bei Gefährdung",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Seitenstreifen zum schnelleren Vorankommen genutzt bei Unfall",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In Ein- oder Ausfahrt von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In Ein- oder Ausfahrt von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Gefährdung",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In Ein- oder Ausfahrt von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Unfall",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf einer Nebenfahrbahn oder dem Seitenstreifen von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen",
          fine: "130 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf einer Nebenfahrbahn oder dem Seitenstreifen von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Gefährdung",
          fine: "160 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf einer Nebenfahrbahn oder dem Seitenstreifen von Autobahnen gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Unfall",
          fine: "195 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Auf der durchgehenden Fahrbahn einer Autobahn gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen",
          fine: "200 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation:
            "Auf der durchgehenden Fahrbahn einer Autobahn gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Gefährdung",
          fine: "240 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation:
            "Auf der durchgehenden Fahrbahn einer Autobahn gewendet oder rückwärts gefahren, um einen möglichen Stau zu umgehen bei Unfall",
          fine: "290 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sich als Motorradfahrer im Stau an anderen Fahrzeugen vorbeigeschlängelt (verbotswidriges Rechtsüberholen)",
          fine: "ab 100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Bei strafbaren Verstößen (z. B. Gefährdung oder Nötigung)",
          fine: "Geldstrafe oder Freiheitsstrafe",
          points: "2 oder 3",
          suspension:
            "Fahrverbot bis 6 Monate oder Entziehung der Fahrerlaubnis",
        },
      ],
    },
    "Bußgelder beim Nichtbilden einer Rettungsgasse": {
      header: "Bußgelder beim Nichtbilden einer Rettungsgasse",
      fines: [
        {
          violation:
            "Sie bildeten auf einer Autobahn oder Außerortsstraße keine freie Gasse zur Durchfahrt von Polizei- oder Hilfsfahrzeugen, obwohl der Verkehr stockte",
          fine: "200 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie bildeten auf einer Autobahn oder Außerortsstraße keine freie Gasse zur Durchfahrt von Polizei- oder Hilfsfahrzeugen, obwohl der Verkehr stockte mit Behinderung",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie bildeten auf einer Autobahn oder Außerortsstraße keine freie Gasse zur Durchfahrt von Polizei- oder Hilfsfahrzeugen, obwohl der Verkehr stockte mit Gefährdung",
          fine: "280 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie bildeten auf einer Autobahn oder Außerortsstraße keine freie Gasse zur Durchfahrt von Polizei- oder Hilfsfahrzeugen, obwohl der Verkehr stockte mit Sachbeschädigung",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie unterließen es, einem Einsatzfahrzeug mit blauem Blinklicht und Martinshorn sofort freie Bahn zu schaffen",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie unterließen es, einem Einsatzfahrzeug mit blauem Blinklicht und Martinshorn sofort freie Bahn zu schaffen mit Gefährdung",
          fine: "280 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie unterließen es, einem Einsatzfahrzeug mit blauem Blinklicht und Martinshorn sofort freie Bahn zu schaffen mit Sachbeschädigung",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie nutzten unberechtigt eine außerorts bei stockendem Verkehr gebildete Rettungsgasse",
          fine: "240 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie nutzten unberechtigt eine außerorts bei stockendem Verkehr gebildete Rettungsgasse mit Behinderung",
          fine: "280 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie nutzten unberechtigt eine außerorts bei stockendem Verkehr gebildete Rettungsgasse mit Gefährdung",
          fine: "300 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation:
            "Sie nutzten unberechtigt eine außerorts bei stockendem Verkehr gebildete Rettungsgasse mit Sachbeschädigung",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
      ],
    },
    "Tiere im Auto": {
      header: "Tiere im Auto: Verstoß gegen Sicherheitsvorschriften",
      fines: [
        {
          violation: "Tiere nicht ausreichend gesichert",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Tiere nicht ausreichend gesichert mit Gefährdung",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Tiere nicht ausreichend gesichert mit Sachbeschädigung",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Tier vom Kfz aus geführt",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Tier ohne Begleiter auf die Straße gelassen",
          fine: "",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Tier ohne Begleiter auf die Straße gelassen mit Gefährdung",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Tier ohne Begleiter auf die Straße gelassen mit Sachbeschädigung",
          fine: "10 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Tunnel: {
      header: "Tunnelverstöße",
      fines: [
        {
          violation: "kein Abblendlicht im Tunnel benutzt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung Anderer",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "...mit Verursachung eines Unfalls",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "in einem Tunnel gewendet",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Gefährdung Anderer",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "...mit Verursachung eines Unfalls",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "in einer Nothalte- und Pannenbucht gehalten",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "in einer Nothalte- und Pannenbucht geparkt",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "mit einem Fahrzeug die Einfahrt eines Autobahntunnels benutzt, obwohl die Ladung/Plane nicht so befestigt war, sodass sie oftmals über 4 Meter reichte und somit die automatische Höhenkontrolle ausgelöst hat, sodass die Fahrtrichtung gesperrt wurde",
          fine: "160 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "mit einem Fahrzeug mit einer Höhe über 4 Metern die Einfahrt eines Autobahntunnels benutzt und die automatische Höhenkontrolle ausgelöst, sodass die Fahrtrichtung gesperrt wurde",
          fine: "240 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Überholen: {
      header: "Überholen: Verstöße beim Überholen anderer Fahrzeuge",
      fines: [
        {
          violation: "Nach dem Überholen einen Überholten behindert",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Seitenabstand nicht eingehalten",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Geschwindigkeit erhöht",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nicht wesentlich schneller gewesen als der zu Überholende",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nicht wesentlich schneller gewesen als der zu Überholende mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Den nachfolgenden Verkehr gefährdet",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen auf der rechten Spur innerorts",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur innerorts mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Überholen auf der rechten Spur außerorts",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur außerorts mit Gefährdung anderer",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen auf der rechten Spur außerorts mit Sachbeschädigung",
          fine: "145 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen unter Nichtbeachten von Verkehrszeichen (VZ 276, 277)",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen am Fußgängerübergang",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Überholen bei unklarer Verkehrslage",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot (VZ 276, 277, 295, 296, 297)",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot mit Gefährdung",
          fine: "250 €",
          points: "2",
          suspension:
            "1 Monat & je nach Tatbegehung Geldstrafe, Führerscheinentzug und Freiheitsstrafe bis 5 Jahre gemäß § 315c StGB möglich",
        },
        {
          violation:
            "Überholen bei unklarer Verkehrslage und Überholverbot mit Sachbeschädigung",
          fine: "300 €",
          points: "2",
          suspension: "1 Monat",
        },
      ],
    },
    "Überladung Pkw": {
      header:
        "Überladung Pkw: Überschreitung des zulässigen Gesamtgewichts als Fahrzeugführer oder Fahrzeughalter",
      fines: [
        { violation: "um über 5 %", fine: "10 €", points: "", suspension: "" },
        { violation: "um über 10 %", fine: "30 €", points: "", suspension: "" },
        { violation: "um über 15 %", fine: "35 €", points: "", suspension: "" },
        {
          violation: "um über 20 %",
          fine: "95 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 25 %",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 30 %",
          fine: "235 €",
          points: "1",
          suspension: "",
        },
      ],
    },

    "Überladung Lkw": {
      header:
        "Bußgeldkatalog Überladung Lkw: Überschreitung des zulässigen Gesamtgewichts als Fahrzeugführer",
      fines: [
        { violation: "um 2 bis 5 %", fine: "30 €", points: "", suspension: "" },
        { violation: "um über 5 %", fine: "80 €", points: "1", suspension: "" },
        {
          violation: "um über 10 %",
          fine: "110 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 15 %",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 20 %",
          fine: "190 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 25 %",
          fine: "285 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 30 %",
          fine: "380 €",
          points: "1",
          suspension: "",
        },
      ],
    },

    "Überladung Lkw (Fahrzeughalter)": {
      header:
        "Bußgeldkatalog Überladung Lkw: Überschreitung des zulässigen Gesamtgewichts als Fahrzeughalter",
      fines: [
        { violation: "um 2 bis 5 %", fine: "35 €", points: "", suspension: "" },
        {
          violation: "um über 5 %",
          fine: "140 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 10 %",
          fine: "235 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 15 %",
          fine: "285 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 20 %",
          fine: "380 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 25 %",
          fine: "425 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "um über 30 %",
          fine: "425 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Umweltplakette und Umweltbelastung": {
      header: "Umweltplakette und Umweltbelastung",
      fines: [
        {
          violation: "Unnötige Lärm- und Abgasbelästigung",
          fine: "80 €",
          points: "",
          suspension: "Fahrverbot",
        },
        {
          violation:
            "Unnützes Hin- und Herfahren innerorts und dadurch Andere belästigt",
          fine: "100 €",
          points: "",
          suspension: "Fahrverbot",
        },
        {
          violation:
            "Straße beschmutzt oder mit einer Flüssigkeit benetzt und trotz möglicher Gefährdung den Zustand nicht beseitigt oder kenntlich gemacht",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Durch mangelnde Umsicht andere Verkehrsteilnehmer beschmutzt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Gegenstand auf der Straße liegengelassen trotz möglicher Gefährdung",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Umweltzone verkehrswidrig befahren (ohne Umweltplakette)",
          fine: "100 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Unfall: {
      header:
        "Unfall: Verkehrswidriges Verhalten im Zusammenhang mit einem Unfall",
      fines: [
        {
          violation:
            "Liegengebliebenes Fahrzeug nicht abgesichert und als Hindernis kenntlich gemacht",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Als Unfallbeteiligter den Verkehr nicht gesichert oder bei Bagatellen nicht sofort beiseite gefahren",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Als Unfallbeteiligter den Verkehr nicht gesichert oder bei Bagatellen nicht sofort beiseite gefahren mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Unfallspuren beseitigt vor den notwendigen polizeilichen Feststellungen",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Unerlaubtes Entfernen vom Unfallort nach § 142 StGB",
          fine: "",
          points: "3",
          suspension: "",
        },
        {
          violation: "Unterlassene Hilfeleistung",
          fine: "",
          points: "3",
          suspension: "",
        },
        {
          violation: "Fahrlässige Tötung",
          fine: "",
          points: "3",
          suspension: "Straftat nach StGB",
        },
        {
          violation: "Fahrlässige Körperverletzung",
          fine: "",
          points: "3",
          suspension: "Straftat nach StGB",
        },
      ],
    },
    "Verkehrsbeeinträchtigungen § 33 StVO": {
      header: "Verkehrsbeeinträchtigungen § 33 StVO",
      fines: [
        {
          violation:
            "Beeinträchtigung der Wirkung von Verkehrszeichen/-einrichtungen (Bekleben, Abdecken usf.)",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Anbringung von Einrichtungen, die Verkehrszeichen gleichen",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "verbotswidriges Betreiben von Werbung oder Propaganda außerorts (Bild, Schrift, Ton, Licht)",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "verbotswidriges Feilbieten von Waren oder Leistungen auf der Straße",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "verbotswidriges Betreiben eines Lautsprechers im öffentlichen Verkehr",
          fine: "25 €",
          points: "",
          suspension: "",
        },
      ],
    },

    "Verkehrsberuhigter Bereich": {
      header:
        "Überschreitung der Geschwindigkeit im verkehrsberuhigten Bereich (mit Pkw)",
      fines: [
        { violation: "bis 10 km/h", fine: "30 €", points: "", suspension: "" },
        { violation: "11 - 15 km/h", fine: "50 €", points: "", suspension: "" },
        { violation: "16 - 20 km/h", fine: "70 €", points: "", suspension: "" },
        {
          violation: "21 - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "1 Monat",
        },
        {
          violation: "31 - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "61 - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Verkehrsberuhigter Bereich - Weitere Bußgelder": {
      header: "Weitere Bußgelder im verkehrsberuhigten Bereich",
      fines: [
        {
          violation:
            "In einem verkehrsberuhigten Bereich einen Fußgänger behindert",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "In einem verkehrsberuhigten Bereich einen Fußgänger gefährdet",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "In einem verkehrsberuhigten Bereich einen Fußgänger gefährdet inkl. Unfall",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig außerhalb der zum Parken gekennzeichneten Flächen geparkt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig außerhalb der zum Parken gekennzeichneten Flächen geparkt inkl. Behinderung anderer",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig länger als 3 Stunden außerhalb der zum Parken gekennzeichneten Flächen geparkt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Verbotswidrig länger als 3 Stunden außerhalb der zum Parken gekennzeichneten Flächen geparkt inkl. Behinderung anderer",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Aus einem verkehrsberuhigten Bereich auf eine Straße gefahren inkl. Gefährdung anderer",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Aus einem verkehrsberuhigten Bereich auf eine Straße gefahren inkl. Unfall",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Von einem verkehrsberuhigten Bereich auf die Straße gefahren, ohne Blinker zu setzen",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Als Fußgänger den Fahrverkehr unnötig behindert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Schrittgeschwindigkeit nicht eingehalten",
          fine: "15 €",
          points: "",
          suspension: "",
        },
      ],
    },

    Verkehrshindernis: {
      header: "Verkehrshindernis: Behinderung oder Gefährdung des Verkehrs",
      fines: [
        {
          violation: "gefährliches Gerät nicht abgesichert",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Beschmutzung oder Benetzung der Straße",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "verkehrswidrigen Zustand nicht kenntlich gemacht",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "verkehrswidrigen Zustand nicht umgehend beseitigt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Gegenstand auf Straße gebracht bzw. nicht rechtzeitig beseitigt",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "nicht zugelassenes Fahrzeug so abgestellt, dass es den Verkehr behindert oder gefährdet",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Verkehrssicherheit": {
      header:
        "Verkehrssicherheit: Verstöße gegen Sicherheitsregeln im Straßenverkehr",
      fines: [
        {
          violation: "Fahren ohne Sicherheitsgurt (auch in Reisebussen!)",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kind nicht nach Vorschrift gesichert (zum Beispiel An­schnall­pflicht be­achtet und mit Gurt gesichert, aber ohne Kindersitz)",
          fine: "30 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Mehrere Kinder nicht nach Vorschrift gesichert",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Kind ohne jede Sicherung (ohne Gurt und Kindersitz)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Mehrere Kinder ohne jede Sicherung",
          fine: "70 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Nichtbeachtung der Schutzhelm­pflicht",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Fahren mit beeinträchtigtem Gehör (zu laute Musik)",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nutzung eines Mobil- oder Autotelefons ohne Freisprecheinrichtung als Kraftfahrer bei laufendem Motor",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Nutzung eines Mobil- oder Autotelefons ohne Freisprecheinrichtung als Radfahrer",
          fine: "55 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahren mit nicht an die Wetterverhältnisse angepasster Ausrüstung des Fahrzeugs (z. B. ohne Winterreifen gefahren)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Fahren mit nicht an die Wetterverhältnisse angepasster Ausrüstung des Fahrzeugs mit Verkehrsbehinderung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    Vorfahrt: {
      header: "Vorfahrt: Verstöße gegen die Vorfahrtsregeln",
      fines: [
        {
          violation: "An eine Vorfahrtsstraße zu schnell herangefahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Die Regelung zur Vorfahrt 'rechts-vor-links' nicht beachtet - mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Die Regelung zur Vorfahrt 'rechts-vor-links' nicht beachtet - mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Die Regelung zur Vorfahrt 'rechts-vor-links' nicht beachtet - mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Stoppschild überfahren - mit Behinderung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Stoppschild überfahren - mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Stoppschild überfahren - mit Sachbeschädigung",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "An der Haltelinie nicht gehalten",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Zu schnell an einen Zebrastreifen herangefahren, obwohl ein Fußgänger diesen nutzen wollte",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Warnzeichen und Beleuchtung": {
      header:
        "Warnzeichen und Beleuchtung: Verstoß gegen die Vorschriften zur Beleuchtung oder missbräuchliche Nutzung von Warnzeichen",
      fines: [
        {
          violation:
            "Beleuchtung nicht vorschriftsmäßig benutzt oder nicht rechtzeitig abgeblendet oder Beleuchtungseinrichtungen in verdecktem oder beschmutztem Zustand benutzt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Beleuchtung nicht vorschriftsmäßig benutzt oder nicht rechtzeitig abgeblendet oder Beleuchtungseinrichtungen in verdecktem oder beschmutztem Zustand benutzt mit Gefährdung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Beleuchtung nicht vorschriftsmäßig benutzt oder nicht rechtzeitig abgeblendet oder Beleuchtungseinrichtungen in verdecktem oder beschmutztem Zustand benutzt mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nur mit Standlicht oder auf einer Straße mit durchgehender, ausreichender Beleuchtung mit Fernlicht gefahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nur mit Standlicht oder auf einer Straße mit durchgehender, ausreichender Beleuchtung mit Fernlicht gefahren mit Gefährdung",
          fine: "15 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nur mit Standlicht oder auf einer Straße mit durchgehender, ausreichender Beleuchtung mit Fernlicht gefahren mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Kein Abblendlicht am Tage trotz Sichtbehinderung durch Nebel, Schneefall, Regen benutzt innerorts",
          fine: "25 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Kein Abblendlicht am Tage trotz Sichtbehinderung durch Nebel, Schneefall, Regen benutzt außerorts",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Ohne Abblendlicht im Tunnel gefahren",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Haltendes Fahrzeug nicht vorschriftsmäßig beleuchtet",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Haltendes Fahrzeug nicht vorschriftsmäßig beleuchtet mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahrtrichtungsanzeiger nicht wie vorgeschrieben benutzt (vergessen zu 'blinken')",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Warnblinkanlage missbraucht",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Hupe und Lichthupe missbraucht",
          fine: "5 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Hupe und Lichthupe missbraucht mit Belästigung",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Unerlaubte Schallzeichen eingesetzt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Nebelscheinwerfer missbräuchlich benutzt",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Nebelscheinwerfer missbräuchlich benutzt mit Gefährdung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Nebelscheinwerfer missbräuchlich benutzt mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Mit eingeschalteter Nebelschlussleuchte gefahren, obwohl die Sicht nicht durch Nebel behindert war",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Mit eingeschalteter Nebelschlussleuchte gefahren, obwohl die Sicht nicht durch Nebel behindert war mit Gefährdung",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Mit eingeschalteter Nebelschlussleuchte gefahren, obwohl die Sicht nicht durch Nebel behindert war mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
      ],
    },
    Werkstatt: {
      header:
        "Werkstatt: Verstöße gegen Vorschriften über Fahrzeugzustand und Betrieb",
      fines: [
        {
          violation: "Lichter und Leuchten verstoßen",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Verkehrsunsicheres Fahrzeug in Betrieb genommen",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Als Halter den Betrieb angeordnet oder zugelassen",
          fine: "135 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "Defekte Schalldämpferanlage in Betrieb genommen",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Defekter Auspuff betrieben",
          fine: "20 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Fahrzeug nicht zur Sicherheitsprüfung vorgeführt und Frist nicht eingehalten",
          fine: "25 €",
          points: "",
          suspension: "2-4 Monate",
        },
        {
          violation:
            "Fahrzeug nicht zur Sicherheitsprüfung vorgeführt und Frist nicht eingehalten",
          fine: "60 €",
          points: "1",
          suspension: "4-8 Monate",
        },
        {
          violation:
            "Fahrzeug nicht zur Sicherheitsprüfung vorgeführt und Frist nicht eingehalten",
          fine: "75 €",
          points: "1",
          suspension: "über 8 Monate",
        },
      ],
    },
    "Winter: Beleuchtung": {
      header:
        "Beleuchtung im Winter: Verstoß gegen Vorschriften zur Beleuchtung",
      fines: [
        {
          violation:
            "Obwohl die Sicht durch Schneefall behindert war, Abblendlicht nicht eingeschaltet (innerorts)",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Obwohl die Sicht durch Schneefall behindert war, Abblendlicht nicht eingeschaltet (außerorts)",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Obwohl die Sicht durch Schneefall behindert war, Abblendlicht nicht eingeschaltet (innerorts) mit Sachbeschädigung",
          fine: "35 €",
          points: "",
          suspension: "",
        },
        {
          violation:
            "Obwohl die Sicht durch Schneefall behindert war, Abblendlicht nicht eingeschaltet (außerorts) mit Gefährdung",
          fine: "75 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Obwohl die Sicht durch Schneefall behindert war, Abblendlicht nicht eingeschaltet (außerorts) mit Sachbeschädigung",
          fine: "90 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Winter: Geschwindigkeitsüberschreitung (innerorts)": {
      header: "Geschwindigkeitsüberschreitung im Winter (innerorts)",
      fines: [
        { violation: "bis 20 km/h", fine: "80 €", points: "1", suspension: "" },
        {
          violation: "21 km/h - 25 km/h",
          fine: "115 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 km/h - 30 km/h",
          fine: "180 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "31 km/h - 40 km/h",
          fine: "260 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "41 km/h - 50 km/h",
          fine: "400 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 km/h - 60 km/h",
          fine: "560 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 km/h - 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "800 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Winter: Geschwindigkeitsüberschreitung (außerorts)": {
      header: "Geschwindigkeitsüberschreitung im Winter (außerorts)",
      fines: [
        { violation: "bis 20 km/h", fine: "80 €", points: "1", suspension: "" },
        {
          violation: "21 km/h - 25 km/h",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "26 km/h - 30 km/h",
          fine: "150 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "31 km/h - 40 km/h",
          fine: "200 €",
          points: "1",
          suspension: "",
        },
        {
          violation: "41 km/h - 50 km/h",
          fine: "320 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "51 km/h - 60 km/h",
          fine: "480 €",
          points: "2",
          suspension: "1 Monat",
        },
        {
          violation: "61 km/h - 70 km/h",
          fine: "600 €",
          points: "2",
          suspension: "2 Monate",
        },
        {
          violation: "über 70 km/h",
          fine: "700 €",
          points: "2",
          suspension: "3 Monate",
        },
      ],
    },
    "Winter: Geschwindigkeitsüberschreitung mit Lkw und anderen Kfz über 3,5 t zGG (innerorts)":
      {
        header:
          "Geschwindigkeitsüberschreitung im Winter mit Lkw und anderen Kfz über 3,5 t zGG (innerorts)",
        fines: [
          {
            violation: "bis 15 km/h",
            fine: "80 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "16 km/h - 20 km/h",
            fine: "160 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 km/h - 25 km/h",
            fine: "175 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 km/h - 30 km/h",
            fine: "235 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "31 km/h - 40 km/h",
            fine: "340 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 km/h - 50 km/h",
            fine: "560 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "51 km/h - 60 km/h",
            fine: "700 €",
            points: "2",
            suspension: "3 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "800 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Winter: Geschwindigkeitsüberschreitung mit Lkw und anderen Kfz über 3,5 t zGG (außerorts)":
      {
        header:
          "Geschwindigkeitsüberschreitung im Winter mit Lkw und anderen Kfz über 3,5 t zGG (außerorts)",
        fines: [
          {
            violation: "bis 15 km/h",
            fine: "80 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "16 km/h - 20 km/h",
            fine: "140 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "21 km/h - 25 km/h",
            fine: "150 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "26 km/h - 30 km/h",
            fine: "175 €",
            points: "1",
            suspension: "",
          },
          {
            violation: "31 km/h - 40 km/h",
            fine: "255 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "41 km/h - 50 km/h",
            fine: "480 €",
            points: "2",
            suspension: "1 Monat",
          },
          {
            violation: "51 km/h - 60 km/h",
            fine: "600 €",
            points: "2",
            suspension: "2 Monate",
          },
          {
            violation: "über 60 km/h",
            fine: "700 €",
            points: "2",
            suspension: "3 Monate",
          },
        ],
      },
    "Winter: Falsche Reifen": {
      header:
        "Falsche Reifen im Winter: Verstoß gegen Vorschriften zur Verwendung von Winterreifen",
      fines: [
        {
          violation: "Bei Glatteis oder Schnee ohne Winterreifen gefahren",
          fine: "60 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Bei Glatteis oder Schnee ohne Winterreifen gefahren mit Behinderung",
          fine: "80 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Bei Glatteis oder Schnee ohne Winterreifen gefahren mit Gefährdung",
          fine: "100 €",
          points: "1",
          suspension: "",
        },
        {
          violation:
            "Bei Glatteis oder Schnee ohne Winterreifen gefahren mit Unfall",
          fine: "120 €",
          points: "1",
          suspension: "",
        },
      ],
    },
    "Winter: Sonstige Verstöße": {
      header: "Sonstige Verstöße im Winter",
      fines: [
        {
          violation: "Auto warmlaufen lassen",
          fine: "80 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Nur 'Guckloch' in der Frontscheibe freigekratzt",
          fine: "10 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Autodach nicht von Schnee befreit",
          fine: "25 €",
          points: "",
          suspension: "",
        },
        {
          violation: "Mit zugeschneitem Kennzeichen gefahren",
          fine: "5 €",
          points: "",
          suspension: "",
        },
      ],
    },
  };

  const [searchQuery, setSearchQuery] = useState("");

  let filteredFines = data[selectedTable]?.fines;
  if (searchQuery) {
    filteredFines = filteredFines.filter((item) =>
      item.violation.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const handleSearch = (query) => {
    setSearchQuery(query);

    // Initialize an empty array to hold both headers and violations that match the search query
    let suggestions = [];

    // Iterate over each category (header) and its violations
    Object.entries(data).forEach(([key, value]) => {
      // If the category (header) matches the query, add it to the suggestions
      if (key.toLowerCase().includes(query.toLowerCase())) {
        suggestions.push({ type: "header", value: key });
      }

      // Additionally, find any violations within this category that match the query
      const matchingFines = value.fines
        .filter((item) =>
          item.violation.toLowerCase().includes(query.toLowerCase())
        )
        .map((fine) => ({ ...fine, type: "violation", header: key }));

      // Concatenate the matching violations to the suggestions
      suggestions = suggestions.concat(matchingFines);
    });

    setSearchResults(suggestions);
  };
  const selectSuggestion = (suggestion) => {
    setSearchQuery("");
    setSearchResults([]);

    // Check if the suggestion is a header
    if (suggestion.type === "header") {
      // If it's a header, directly set the selected table to this header
      setSelectedTable(suggestion.value);
    } else if (suggestion.type === "violation") {
      // For violations, proceed as before to set the selected table to the category of the violation
      const categoryKey = Object.keys(data).find((key) =>
        data[key].fines.some((fine) => fine.violation === suggestion.violation)
      );

      if (categoryKey) {
        setSelectedTable(categoryKey);
      }
    }

    // Additional logic for violations could go here, e.g., highlighting the selected violation
  };

  const getFirstDayOfCurrentMonth = () => {
    const now = new Date();

    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

    const day = String(firstDay.getDate()).padStart(2, "0");

    const month = String(firstDay.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed

    const year = firstDay.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const firstDayOfCurrentMonth = getFirstDayOfCurrentMonth();
  

  return (
    <>
    <div className="prose mx-auto p-4 lg:p-6 lg:pr-[10%] lg:pl-[20%] font-para text-sm lg:text-[15px]">
 <div className="flex flex-row px-4 justify-start gap-6 onlyMobile ">
          <Link to="/">
            <img
              src={logo}
              alt=""
              className="h-[2.2rem] mx-auto flex-grow mt-2"
            />
          </Link>
          <div className="relative z-50">
            <Hamburger
              toggled={isMobileMenuOpen}
              toggle={setIsMobileMenuOpen}
              color="#000000"
              size={20}
            />
          </div>
        </div>
        {/* END mobile menu */}

      <Link to="/"><img src={logo} alt="" className="h-[2rem] mx-auto mt-6 max-sm:hidden" /></Link> 

      <div className="flex flex-row justify-center gap-4 lg:gap-8 mx-auto text-xs lg:text-sm mt-6 max-sm:hidden">
        <Link to="/Upload">
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out ">
           Bußgeld Prüfen
          </p>
        </Link>
        <Link to="/Impressum">
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out ">
            IMPRESSUM
          </p>
        </Link>
        <Link to="/Datenschutz">
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            DATENSCHUTZ
          </p>
        </Link>
        <Link to="/AGB">
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            AGB
          </p>
        </Link>
      </div>

      <h1 className="mt-12 font-header font-black tracking-tight text-xl uppercase text-center text-slate-700">
       Der Bußgeldkatalog
      </h1>
      <h2 className=" font-header font-medium tracking-widest text-sm uppercase text-center text-slate-700">Stand: {firstDayOfCurrentMonth}</h2>

      <div className="flex flex-row gap-4 mt-4">
        {/* Select Dropdown */}
        <select
          className="mt-4 p-2 border-2 border-gray-200 rounded-lg h-[2.4rem] w-full max-w-xs"
          onChange={(e) => setSelectedTable(e.target.value)}
          value={selectedTable}
        >
          {Object.keys(data).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>

        <div className="mt-4 mb-4 max-w-xs w-full relative">
          <input
            type="text"
            placeholder="Suchen..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            className="pl-10 p-2 border-2 border-gray-200 rounded-lg w-full"
          />
          <img
            src={SearchIcon}
            className="absolute left-2 top-1/2 transform -translate-y-1/2"
            alt="Search"
          />
        </div>
        <button
          onClick={toggleChat}
          className=" flex fixed bottom-4 right-4 bg-white border-2 border-blue-400 text-black p-2 rounded-full shadow-lg"
        >
          <img src={SearchIcon} alt="" className="mt-[.2rem]" />
          Bußgeld Finder
        </button>
      </div>
      {searchQuery && (
        <div className="mt-2 border rounded-lg overflow-hidden">
          {searchResults.map((item, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-50 cursor-pointer"
              onClick={() => selectSuggestion(item)}
            >
              {item.type === "header" ? (
                <strong>{item.value}</strong>
              ) : (
                item.violation
              )}
            </div>
          ))}
        </div>
      )}

      {/* Dynamic Header based on Selected Table */}
      <h1 ref={tableRef} className="text-md font-bold mt-4 text-slate-700">{data[selectedTable].header}</h1>

      {/* Data Table */}
      <div className="mt-4 overflow-x-auto shadow-lg rounded-lg">
        <table className="w-full text-left table-auto">
          <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
            <tr>
              <th className="px-4 py-2">Verstoß</th>
              <th className="px-4 py-2">Strafe</th>
              <th className="px-4 py-2">Punkte</th>
              <th className="px-4 py-2">Fahrverbot</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredFines.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-4 py-2">{item.violation}</td>
                <td className="px-4 py-2">{item.fine}</td>
                <td className="px-4 py-2">{item.points}</td>
                <td className="px-4 py-2">{item.suspension}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        <p className="font-para text-center mt-1 text-[12px] mb-12">Alle Bussgelder zzgl. mindestens 28.50€ Bearbeitungsgebühr.</p>
      
      
      {/* Conditional Rendering of Articles */}
      {selectedTable === '30er Zone' && <Article30erZone />}
      {selectedTable === 'Abstandsvergehen' && <ArticleAbstandsvergehen />}
      {selectedTable === 'Abbiegen, Wenden, Rückwärtsfahren' && <ArticleAbbiegen />}
      {/* Add more conditional rendering as needed for other articles */}


      {/* Footer */}
      <div className="mb-4">
        <img src={JSOnly} alt="" className="h-6 mx-auto mt-12" />
        <p className="font-para uppercase tracking-wide mx-auto text-center text-xs lg:text-[9px] mt-2">
          Ein Service der
        </p>
        <p className="font-para uppercase tracking-wider mx-auto text-center text-xs lg:text-[9px] font-bold">
          JuraSolutions Rechtsanwaltsgesellschaft mbH
        </p>
      </div>
    </div>
    {isMobileMenuOpen && (
      <motion.div
        className="fixed top-0 right-0 h-full bg-white z-10 border-l-8 border-slate-700"
        initial={{ x: "100%" }} // Start from the right
        animate={{ x: 0 }} // End at the original position
        exit={{ x: "100%" }} // Exit to the right
        transition={{ type: "tween", duration: 0.3 }} // Customize the transition
      >
        <div className="flex flex-col items-start justify-start p-6 space-y-4 text-slate-700">
          <h2 className="text-slate-700 font-header font-black text-[2rem] mt-6">
            MENU
          </h2>
    
          <Link to="/Upload" className="w-full font-para uppercase">
            Bussgeld Hochladen
          </Link>
    
          <Link to="/Impressum" className="w-full font-para uppercase">
            IMPRESSUM
          </Link>
    
          <Link to="/Datenschutz" className="w-full font-para uppercase">
            DATENSCHUTZ
          </Link>
    
          <Link to="/AGB" className="w-full font-para uppercase">
            AGB
          </Link>
        </div>
      </motion.div>
    )}
    {isChatOpen && (
      <ChatWindow
        onClose={toggleChat}
        data={data}
        setSelectedTable={setSelectedTable}
      />
    )}
    </>
  );
}

export default Katalog;
