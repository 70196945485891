import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./Components/Homepage";
import Upload from "./Components/Upload";
import Punkte from "./Components/Punkte";
import Wann from "./Components/Wann";
import ZuAlt from "./Components/ZuAlt";
import Einspruch from "./Components/Einspruch";
import RSAbfrage from "./Components/RSAbfrage";
import Sorry from "./Components/Sorry";
import Impressum from "./Components/Impressum";
import Datenschutz from "./Components/Datenschutz";
import AGB from "./Components/AGB";
import Vsn from "./Components/Vsn";
import Katalog from "./Components/Katalog";
import "./tailwind.css";
import "./globals.css";
import CookieConsent from "react-cookie-consent";

function App() {
  const [errorOccurred, setErrorOccurred] = useState(false); // Add state to track error
  const [initialImage, setInitialImage] = useState(null); // State to hold the initial image
  const [filePath, setFilePath] = useState(""); // State to hold the file path

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        cookieName="userConsentCookie"
        style={{
          background: "#2B373B",
          fontSize: "18px",
          fontFamily: "Mont",
        }}
        buttonStyle={{
          background: "#0077B6",
          fontSize: "13px",
          color: "#ffffff",
        }}
        declineButtonStyle={{
          background: "#000000",
          fontSize: "13px",
          fontFamily: "Mont",
        }}
        expires={150}
        enableDeclineButton
        flipButtons
        onDecline={() => {
          console.log("Consent declined");
        }}
      >
        Diese Webseite verwendet Cookies um den Seiteninhalt zu optimieren. &nbsp;&nbsp;&nbsp;
        <a href="/Datenschutz" style={{ color: "#0077B6", fontWeight: "bold", fontSize:"14px", fontFamily: "Mont", }}>
           Mehr Informationen
        </a>
      </CookieConsent>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/upload" element={<Upload setErrorOccurred={setErrorOccurred} setInitialImage={setInitialImage} setFilePath={setFilePath} />} />
          <Route path="/Punkte" element={<Punkte />} />
          <Route path="/Wann" element={<Wann />} />
          <Route path="/ZuAlt" element={<ZuAlt />} />
          <Route path="/Einspruch" element={<Einspruch />} />
          <Route path="/RSAbfrage" element={<RSAbfrage />} />
          <Route path="/Sorry" element={<Sorry />} />
          <Route path="/Impressum" element={<Impressum />} />
          <Route path="/Datenschutz" element={<Datenschutz />} />
          <Route path="/AGB" element={<AGB />} />
          <Route path="/Vsn" element={<Vsn />} />
          
          <Route path="/Bussgeldkatalog" element={<Katalog />} />
        </Routes>

              </Router>
    </>
  );
}

export default App;