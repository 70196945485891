import React, { useState, useEffect } from "react";
import logo from "../images/logo.svg";
import arrow from "../images/arrow.svg";
import ShowSignature from './ShowSignature'; 
import { isValidPhoneNumber } from 'libphonenumber-js';
import {sendUserData} from "../utils/tracking.js"



function RSEingabe({ file, data }) {
  useEffect(() => {
    sendUserData('Email und Phone Eingabe'); 
  }, []);
  const [inputData, setInputData] = useState({
    email: "",
    phone: "",
    countryCode: "+49", // Default country code

  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fullPhoneNumber = inputData.countryCode + inputData.phone;
    
    if (!isValidPhoneNumber(fullPhoneNumber)) {
      setErrorMessage('Bitte geben Sie eine gültige Telefonnummer ein.');
      return;
    }

    console.log("Form submitted with data:", inputData, "and file:", file);
    setFormSubmitted(true);
  };

  if (formSubmitted) {
    return <ShowSignature 
      file={file} 
      email={inputData.email} 
      phone={inputData.countryCode + inputData.phone} 
      data={data} 
    />
  } else {
    return (
      <>
        <div className="px-12 py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw] max-sm:px-2">
          <img src={logo} alt="" className="h-[2rem] mx-auto mt-4" />
          <div className="font-para text-[18px] max-sm:text-[14px] leading-[24px] text-primary mt-12 px-8 max-sm:px-2 max-sm:max-w-[95vw]">
            <p>
              Um für Sie tätig werden zu dürfen, benötigen wir einige kleine
              Angaben. Zudem brauchen wir 2 Unterschriften für die
              Wiederrufsbelehrung und eine für die Vollmacht. <br></br>
              <br></br>
            </p>

            <p>
              Wir werden sodann umgehend: <br></br> <br></br>
            </p>

            <ul
              className="leading-6"
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
            >
              <li>Einspruch bei der zuständigen Behörde einlegen</li>
              <li>die Bußgeldakte anfordern</li>
              <li>
                eine Deckungszusage bei der Rechtsschutzversicherung einholen
              </li>
            </ul>
          </div>
          
          <form onSubmit={handleSubmit} className="grid mx-auto mt-8 max-sm:max-w-[95vw] ">
            
            <p className="font-para font-black uppercase tracking-widest text-[.7rem] mt-8 leading-[30px] ml-[5px]">
            E-Mail:
            </p>

            <input
              type="text"
              id="email"
              name="email"
              value={inputData.email}
              onChange={handleInputChange}
              className="border-2 max-sm:max-w-[80vw] border-slate-300 rounded-sm px-2 w-[500px] text-[.7rem] font-para mx-auto"
              placeholder="z.B. Max@gmail.com"
            />
            <p className="font-para font-black uppercase tracking-widest text-[.7rem] mt-4 leading-[30px] ml-[5px]">
            Telefonnummer:
            </p>

            <div className="flex flex-row font-para">
            <select name="countryCode" value={inputData.countryCode} onChange={handleInputChange} className="border-2 border-slate-300 rounded-sm px-2 w-[80px] text-[.7rem] font-para mx-auto bg-white">
  <option value="+1" className="bg-white font-bold">+1 USA/Canada</option>
  <option value="+44" className="bg-white font-bold">+44 UK</option>
  <option value="+49" className="bg-white font-bold">+49 Deutschland</option>
  <option value="+86" className="bg-white font-bold mt-1">+86 China</option>
  <option value="+91" className="bg-white font-bold">+91 India</option>
  <option value="+81" className="bg-white font-bold">+81 Japan</option>
  <option value="+33" className="bg-white font-bold">+33 France</option>
  <option value="+34" className="bg-white font-bold">+34 Spain</option>
  <option value="+39" className="bg-white font-bold">+39 Italy</option>
  <option value="+55" className="bg-white font-bold">+55 Brazil</option>
  <option value="+61" className="bg-white font-bold">+61 Australia</option>
  <option value="+7" className="bg-white font-bold">+7 Russia</option>
  <option value="+27" className="bg-white font-bold">+27 South Africa</option>
  <option value="+82" className="bg-white font-bold">+82 South Korea</option>
  <option value="+31" className="bg-white font-bold">+31 Netherlands</option>
  <option value="+46" className="bg-white font-bold">+46 Sweden</option>
  <option value="+47" className="bg-white font-bold">+47 Norway</option>
  <option value="+48" className="bg-white font-bold">+48 Poland</option>
  <option value="+51" className="bg-white font-bold">+51 Peru</option>
  <option value="+52" className="bg-white font-bold">+52 Mexico</option>
</select>

                      

                      
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={inputData.phone}
                        onChange={handleInputChange}
                        className="border-2 max-sm:max-w-[80vw] border-slate-300 rounded-sm px-2 w-[410px] text-[.7rem] font-para mx-auto"
                        placeholder="z.B. 161 2818283"
                      />
            </div>
                {errorMessage && <div className="text-[.7rem] font-para text-red-400 ">{errorMessage}</div>}

            <button
              type="submit"
              className="flex flex-row mt-12 mb-12 justify-center gap-2 ml-4 group cursor-pointer"
            >
              <p className="font-para tracking-widest text-primary text-2xl">
                ABSENDEN
              </p>
              <img
                src={arrow}
                alt=""
                className="h-4 mt-2 scale-0 group-hover:scale-100 duration-300 ease-in-out"
              />
            </button>
          </form>
        </div>
      </>
    );
  }
}

export default RSEingabe;