import React, { useEffect, useRef, useState } from "react";

import { createNoise3D } from "simplex-noise";

import "../globals.css";

import { cn } from "../utils/cn"; // Adjust the import path based on your folder structure



const WavyBackground = ({

  children,

  className,

  containerClassName,

  colors,

  waveWidth,

  backgroundFill,

  blur = 10,

  speed = "fast",

  waveOpacity = 0.5,

  ...props

}) => {

  const noise = createNoise3D();

  let w, h, nt, i, x, ctx, canvas;

  const canvasRef = useRef(null);



  const getSpeed = () => {

    switch (speed) {

      case "slow":

        return 0.001;

      case "fast":

        return 0.002;

      default:

        return 0.001;

    }

  };



  const init = () => {

    canvas = canvasRef.current;

    ctx = canvas.getContext("2d");



    const resizeCanvas = () => {

      w = ctx.canvas.width = window.innerWidth;

      h = ctx.canvas.height = window.innerHeight;

      ctx.filter = `blur(${blur}px)`;

    };



    resizeCanvas();

    window.addEventListener("resize", resizeCanvas);



    nt = 0;

    render();

  };



  const waveColors = colors ?? [

    "#ADD8E6",

    "#87CEEB",

    "#4682B4",

    "#0096FF",

    "#89CFF0",

  ];



  const drawWave = (n) => {

    nt += getSpeed();

    for (i = 0; i < n; i++) {

      ctx.beginPath();

      ctx.lineWidth = waveWidth || 50;

      ctx.strokeStyle = waveColors[i % waveColors.length];

      for (x = 0; x < w; x += 5) {

        var y = noise(x / 800, 0.3 * i, nt) * 100;

        ctx.lineTo(x, y + h * 0.5); // adjust for height, currently at 50% of the container

      }

      ctx.stroke();

      ctx.closePath();

    }

  };



  let animationId;

  const render = () => {

    ctx.fillStyle = backgroundFill || "black";

    ctx.globalAlpha = waveOpacity || 0.5;

    ctx.fillRect(0, 0, w, h);

    drawWave(5);

    animationId = requestAnimationFrame(render);

  };



  useEffect(() => {

    init();

    return () => {

      cancelAnimationFrame(animationId);

    };

  }, []);



  const [isSafari, setIsSafari] = useState(false);



  useEffect(() => {

    // I'm sorry but I have got to support it on safari.

    setIsSafari(

      typeof window !== "undefined" &&

        navigator.userAgent.includes("Safari") &&

        !navigator.userAgent.includes("Chrome")

    );

  }, []);



  return (

    <div

      className={cn(

        "h-fit flex flex-col items-center justify-center overflow-y-hidden max-w-[100vw] overflow-x-hidden p-0",

        containerClassName

      )}

    >

      <canvas

        className="absolute inset-0 z-0 w-full h-full"

        ref={canvasRef}

        id="canvas"

        style={{ ...(isSafari ? { filter: `blur(${blur}px)` } : {}) }}

      ></canvas>



      <div className={cn("relative z-10", className)} {...props}>

        {children}

      </div>

    </div>

  );

};



export default WavyBackground;