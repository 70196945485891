import { getCookieConsentValue } from 'react-cookie-consent';


export const sendUserData = (componentName = '') => {
  const consentGiven = getCookieConsentValue("userConsentCookie");
  if (consentGiven !== "true") return; // Stop the function if consent not given
  const userAgent = navigator.userAgent;
  const language = navigator.language || navigator.userLanguage;

  fetch("https://api.ipify.org?format=json")
    .then(response => response.json())
    .then(data => {
      const ipAddress = data.ip;

      fetch(`https://ipinfo.io/${ipAddress}/geo`)
        .then(response => response.json())
        .then(data => {
          const userData = {
            ip_address: ipAddress,
            browser: userAgent,
            operating_system: navigator.platform,
            language: language,
            region: data.region,
            country: data.country,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            timezoneOffset: new Date().getTimezoneOffset(),
            referrer: componentName, // Map componentName to referrer
          };

          fetch("/api/saveBussgeldData", {
            method: "POST",
            body: JSON.stringify(userData),
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.json();
          })
          .then(data => console.log("Data successfully sent to the server:", data))
          .catch(error => console.error("Failed to send data to the server:", error));
        });
    });
};