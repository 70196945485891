import React, { useState, useEffect } from "react";
import Wann from './Wann';
import Sorry from './Sorry';
import Kostet from "./Kostet";
import {sendUserData} from "../utils/tracking.js"


function RSAbfrage({ data, file }) {
  const [currentView, setCurrentView] = useState('form');
  useEffect(() => {
    sendUserData('Rechtsschutzabfrage');
  }, []);

  const handleDisplayChange = (view) => {
    // If the user clicks a button that would normally lead to 'sorry',
    // check the Fahrverbot condition to decide between 'sorry' and 'kostet'.
    if (view === 'sorry' && data['fahrverbot'] && data['fahrverbot'].toLowerCase() !== 'nein') {
      setCurrentView('kostet');
    } else {
      setCurrentView(view);
    }
  };
  console.log("RSAbfrage received file:", file);

  useEffect(() => {
    console.log("RSAbfrage received file:", file);
  }, [file]);

  const renderContent = () => {
    switch (currentView) {
      case 'form':
        return (
          <div className="p-10 content-center grid justify-center max-sm:p-0 ">
              <h2 className="font-para text-salte-700 font-black text-center uppercase mt-12">
            {" "}
            Lohnt sich ein EINSPRUCH?
          </h2>
            <p className="font-para  text-primary text-center  text-[20px] max-sm:text-[14px] leading-[15px] mt-6 ">
              Das kommt drauf an...<br/><br/>Sind Sie rechtsschutzversichert?
            </p>
            <button
              onClick={() => handleDisplayChange('sorry')}
              className="border-2 font-para border-secondary bg-transparent rounded-lg p-2 mx-auto hover:bg-secondary hover:text-white duration-300 ease-in-out mt-12 text-[18px]"
            >
              Nein.
            </button>
            <button
              onClick={() => handleDisplayChange('wann')}
              className="border-2 font-para border-secondary bg-transparent rounded-lg p-2 mx-auto hover:bg-secondary hover:text-white duration-300 ease-in-out mt-4 text-[18px]"
            >
              Ja.
            </button>
            
          </div>
        );
      case 'wann':
        return <Wann  file={file} data={data} />;
      case 'sorry':
        return <Sorry />;
      case 'kostet':
        return <Kostet />;
      default:
        return null; // or any default component
    }
  };

  return renderContent();
}

export default RSAbfrage;