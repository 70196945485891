import React, { useState, useEffect } from 'react';
import logo from '../images/logo.svg';
import arrow from '../images/arrow.svg';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import {sendUserData} from "../utils/tracking.js"


function Kostet() {
  useEffect(() => {
    sendUserData('Einspruch lohnt aber kostet'); 
  }, []);

  return (
    <div className="px-12 py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw]">
      <img src={logo} alt="" className='h-[2rem] mx-auto mt-4'/>
        <p className='font-para  text-primary text-center mt-12 mb-12 text-sm'><span className='font-black'>Ja, ein Einspruch würde sich lohnen.</span> <br></br><br></br>Jedoch würden hierbei Kosten für Sie anfallen.<br></br><br></br>Gerne können Sie uns für eine kostenlose Erstberatung kontaktieren. 
        <br></br><br></br>
       MON - FREI: 09:00 - 17:00 UHR  <br></br><br></br> +49 2736 2009650

        
      </p>
    
    </div>
  );
}

export default Kostet;