import React, { useEffect, useState, useRef } from "react";
import Vsn from "./Vsn"; // Ensure this path is correct
import {sendUserData} from "../utils/tracking.js"


function ShowSignature({ file, email, phone, data, vsn, versicherer }) {
  useEffect(() => {
    sendUserData('Signatur Fenster'); 
  }, []);
  const [signatureLink, setSignatureLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [signatureCompleted, setSignatureCompleted] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [signatureRequestId, setSignatureRequestId] = useState(null);

  const generateVollmachtCalled = useRef(false); // Ref to track if the effect ran

  const firstName = data?.firstname;
  const lastName = data?.lastname;

  useEffect(() => {
    if (signatureLink || !file || generateVollmachtCalled.current) {
      return;
    }

    generateVollmachtCalled.current = true; // Mark as called

    const generateVollmacht = async () => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      if (email && phone) {
        formData.append("email", email);
        formData.append("phone", phone);
      }
      if (firstName && lastName) {
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
      } else {
        console.warn("FirstName or LastName is missing in data prop.");
      }

      try {
        const response = await fetch("/api/extract-and-generate-vollmacht", {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (data.signatureLink && data.signatureRequestId) {
          setSignatureLink(data.signatureLink);
          setDocumentId(data.documentId);
          setSignatureRequestId(data.signatureRequestId);
        } else {
          console.error("Required data not received.", data);
        }
      } catch (error) {
        console.error("Error generating Vollmacht:", error);
      }
      setIsLoading(false);
    };

    generateVollmacht();
  }, [file, email, phone, firstName, lastName]);

  useEffect(() => {
    if (signatureLink) {
      const yousign = new window.Yousign({
        signatureLink: signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: true,
      });

      // Listen for the success event
      yousign.onSuccess(() => {
        // Setting signatureCompleted to true as the signature process is complete
        setSignatureCompleted(true);
      });
    }
  }, [signatureLink, file]);

  // Conditional rendering based on isLoading state
  if (isLoading) {
    return (
      <div className="justify-center mt-[4rem] mb-[5rem]">
        <svg
          className="ip" // Corrected from 'class' to 'className'
          viewBox="0 0 256 128"
          width="256px"
          height="128px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stop-color="#07E2F5" />
              <stop offset="33%" stop-color="#2BBAF4" />
              <stop offset="67%" stop-color="#2490DC" />
              <stop offset="100%" stop-color="#0071BC" />
            </linearGradient>
            <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stop-color="#0071BC" />
              <stop offset="33%" stop-color="#2490DC" />
              <stop offset="67%" stop-color="#2BBAF4" />
              <stop offset="100%" stop-color="#07E2F5" />
            </linearGradient>
          </defs>
          <g fill="none" stroke-linecap="round" stroke-width="16">
            <g class="ip__track" stroke="#ddd">
              <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
              <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
            </g>
            <g stroke-dasharray="180 656">
              <path
                class="ip__worm1"
                stroke="url(#grad1)"
                stroke-dashoffset="0"
                d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
              />
              <path
                class="ip__worm2"
                stroke="url(#grad2)"
                stroke-dashoffset="358"
                d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
              />
            </g>
          </g>
        </svg>
        <p id="loading-text" className="font-para text-[.7rem] text-slate-500">
          Generiere Vollmacht<span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      </div>
    );
  }

  if (signatureCompleted) {
    // Transition to Vsn component upon successful signature completion, passing along all necessary data
    return (
      <Vsn
        file={file}
        email={email}
        phone={phone}
        data={data}
        vsn={vsn}
        versicherer={versicherer}
        documentId={documentId}
        signatureRequestId={signatureRequestId}
        signatureCompleted={signatureCompleted}
        firstName={firstName}
        lastName={lastName}
      />
    );
  }
  return (
    <>
      {signatureLink && (
        <div
          id="iframe-container"
          style={{ width: "100%", height: "90vh" }}
        ></div>
      )}
    </>
  );
}

export default ShowSignature;