import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {sendUserData} from "../utils/tracking.js"



function Impressum() {
  useEffect(() => {
    sendUserData('Impressum'); 
  }, []);
  return (
    <div className="prose prose-lg mx-auto p-6 lg:pr-[30%] lg:pl-[20%] font-para text-[15px] blocksatz">
      <div className="flex flex-row justify-center gap-8 mx-auto text-sm mt-6 max-sm:max-w-[95vw] max-sm:text-[.5rem] MobileHide">
        <Link to='/'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            HOME
          </p>
        </Link>
        <Link to='/Datenschutz'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            DATENSCHUTZ
          </p>
        </Link>
        <Link to='/AGB'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            AGB
          </p>
        </Link>
      </div>
      <h1 className="mt-10 font-header font-black uppercase text-secondary text-3xl">
        Impressum
      </h1>
      <p className="font-black text-[18px] mb-1 mt-4">
        JurasolutionsRechtsanwaltsgesellschaft mbH
      </p>
      <p>
        Vertr. d.d. Geschäftsführer
      </p>
      <p>
        Rechtsanwälte Karlheinz Kutschenreiter, Nikolai Kröger
      </p>
      <p>
        HRB 7765 AG Wetzlar
      </p>
      <p>
        Kalteiche Ring 46
      </p>
      <p>
        35708 Haiger
      </p>
      <p>
        Telefon: +49 2736 20096 50
      </p>
      <p>
        E-Mail: service@jurasolutions.de
      </p>
      <p>
        StNr: DE267566152
      </p>
      <p className="font-black text-[18px] mb-1 mt-4">
        Berufshaftpflichtversicherung
      </p>
      <p>
        R+V Allgemeine Versicherung AG
      </p>
      <p>
        Raiffeisenplatz 1
      </p>
      <p>
        65189 Wiesbaden
      </p>
      <p>
        www.ruv.de
      </p>
      <p>
        Tel: 0800 533- 1116
      </p>
      <p className="font-black text-[18px] mb-1 mt-4">
        Zuständige Rechtsanwaltskammer
      </p>
      <p>
        Rechtsanwaltskammer Frankfurt am Main
      </p>
      <p>
        Bockenheimer Anlage 36
      </p>
      <p>
        60322 Frankfurt am Main
      </p>
      <p>
        Telefon: +49 69 170098-01
      </p>
      <p>
        Telefax: +49 69 170098-51
      </p>
      <p>
        E-Mail: info@rak-ffm.de
      </p>
      <p className="font-black text-[18px] mb-1 mt-4">
        Maßgebliche berufsrechtliche Regelungen
      </p>
      <p className="mb-2">
        Für unsere Tätigkeit gelten die nachfolgend genannten berufsrechtlichen Regelungen:
      </p>
      <p>
       - Bundesrechtsanwaltsordnung (BRAO)
      </p>
      <p>
        - Berufsordnung für Rechtsanwälte (BORA)
      </p>
      <p>
        - Fachanwaltsordnung (FAO)
      </p>
      <p>
        - Rechtsanwaltsvergütungsgesetze (RVG)
      </p>
      <p className="mt-2">
        Darüber hinaus gelten für den internationalen Rechtsverkehr die Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft (CCBE) sowie das Gesetz für die Tätigkeit europäischer Rechtsanwälte in Deutschland (EuRAG).
      </p>
      <p>
        Die berufsrechtlichen Vorschriften der BRAO, BORA, FAO und des RVG können auf der Homepage der Bundesrechtsanwaltskammer unter www.brak.de eingesehen werden.
      </p>
   
      <h2 className="mt-6 mb-4 font-bold text-lg">
        Haftungsausschluss
      </h2>
      <p className="blocksatz">
        Die bereitgestellten Informationen auf der Website wurden sorgfältig geprüft und werden regelmäßig aktualisiert. Jedoch kann keine Haftung oder Garantie dafür übernommen werden, dass alle Angaben zu jeder Zeit vollständig, richtig und aktuell sind. Dies gilt insbesondere für alle Verbindungen („Links“) zu anderen Websites, auf die direkt oder indirekt verwiesen wird. Hinsichtlich der Links erklären wir ausdrücklich, dass sie keinen Einfluss auf Gestaltung und Inhalte der verlinkten Seiten hat und sich die Inhalte nicht zu eigen macht. Der Inhalt dieser Website ist urheberrechtlich geschützt. Jede ganz oder teilweise Speicherung, Vervielfältigung oder sonstige Nutzung der auf dieser Website oder Profil bereitgestellten Texte bedarf der vorherigen schriftlichen Zustimmung. Aus Gründen des Urheberrechts ist auch die Speicherung und Vervielfältigung von Bildmaterial oder Grafiken aus dieser Website nicht gestattet.
      </p>
      <p>
        Urheberrechtshinweise und Markenbezeichnungen dürfen weder verändert noch beseitigt werden. Der Abruf und die Nutzung unserer Website dürfen nur in einer Art und Weise geschehen, die die Nutzung des Online-Angebots durch andere Besucher und Nutzer nicht beeinträchtigt. Alle darüber hinausgehenden Handlungen im Zusammenhang mit dieser Internetpräsentation bedürfen der vorherigen schriftlichen Zustimmung. Ansprechpartner für die Website und ggf. inhaltlich Verantwortlicher: Rechtsanwalt Karlheinz Kutschenreiter Kontakt: siehe oben.
      </p>
      <h2 className="mt-6 mb-4 font-bold text-lg">
        Streitbeilegung
      </h2>
      <p>
        In Fall einer vermögensrechtlichen Streitigkeit aus dem Mandatsverhältnis erklärt sich die JuraSolutions Rechtsanwaltsgesellschaft mbH nicht bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Plattform der EU zur außergerichtlichen Online-Streitbeilegung: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>
      </p>
      <p>
        Diese Website verwendet Cookies.
      </p>
    </div>
  );
}

export default Impressum;