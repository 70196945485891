import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../chat.css";
import "../globals.css";

function ChatWindow({ onClose, data, setSelectedTable }) {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [context, setContext] = useState({});
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async (userInput) => {
    if (!userInput || userInput.trim() === '') return;

    const newMessages = [...messages, { text: userInput, sender: 'user' }];
    setMessages(newMessages);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/katalog_chat', {
        message: userInput,
        data: JSON.stringify(data),
        context: JSON.stringify(context)
      });

      const botResponse = response.data.message;
      const newContext = response.data.context || {};
      setContext(newContext);

      newMessages.push({ text: botResponse, sender: 'bot' });
      setMessages(newMessages);

      if (newContext.selectedTable && data.hasOwnProperty(newContext.selectedTable)) {
        setSelectedTable(newContext.selectedTable);
        onClose();
      }
    } catch (error) {
      console.error('Error sending message:', error);
      newMessages.push({ text: 'Sorry, there was an error processing your request.', sender: 'bot' });
      setMessages(newMessages);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (messages.length === 0) {
      setMessages([{ text: "Sie können dieses Chatfenster nutzen, um in natürlicher Sprache das Verkehrsgeldbuße zu erklären, das Sie suchen. Wir könnten Rückfragen stellen, aber sobald wir genügend Informationen haben, wird das Chatfenster geschlossen und die entsprechende Tabelle geöffnet.", sender: 'bot' }]);
    }
  }, []);

  return (
    <div className="fixed bottom-4 right-4 w-80 h-96 bg-[#000000] shadow-xl rounded-lg flex flex-col font-para">
      <p className="text-white font-para tracking-widest text-center text-[12px] p-2">BUßGELD FINDER</p>
      <div className="flex-grow overflow-y-auto px-4 pt-0 scrollable-content rounded-xl max-w-[100%] overflow-x-hidden pr-6 bg-[#000000]">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === 'user' ? 'user' : 'assistant'}-message slide-up`}
            style={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              position: "relative",
              marginBottom: "1em",
            }}
          >
            {message.text.split("\n\n").map((text, i) => (
              <p key={i}>{text.split("\n").join(" ")}</p>
            ))}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex items-center p-2 px-0 rounded-lg">
        {isLoading ? (
          <div className="lds-ripple mx-auto">
            <div></div>
            <div></div>
          </div>
        ) : (
          <>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage(input)}
              className="flex-grow p-2 border-0 border-slate-400 text-white rounded-md max-w-[75%] ml-4 max-sm:max-w-[70%] max-sm:mx-auto resize-none text-[14px] focus:outline-none bg-[#4e4e4e90]"
              placeholder="Beschreibung des Vergehens..."
              disabled={isLoading}
            />
            <button
              onClick={() => sendMessage(input)}
              disabled={isLoading}
              className="ml-2 transition duration-300 hover:scale-95 active:scale-50 max-sm:mr-4 max-sm:-ml-2"
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19 16"
                className="h-[1rem] fill-current text-white hover:text-blue-500"
              >
                <path
                  className="cls-1"
                  d="M0,16V0L19,8,0,16Zm2-3l11.85-5L2,3v3.5l6,1.5-6,1.5v3.5Zm0,0v0Z"
                />
              </svg>
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ChatWindow;
