import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import uploadWhite from "../images/upload-white.png";
import analysis from "../images/analysis.svg";
import RSAbfrage from "./RSAbfrage";
import Punkte from "./Punkte";
import NeinSorry from "./NeinSorry";
import SignatureFrame from "./Signature";
import "./SignupGood.css";
import RSEingabe from "./RSEingabe";
import ShowSignature from "./ShowSignature";
import UploadImage from "../images/upload-field.jpg";
import logo from "../images/logo.svg";
import JSOnly from "../images/JSOnly-White.svg";
import phone from "../images/phone.png";
import email from "../images/email.png";
import { sendUserData } from "../utils/tracking.js";
import { motion } from 'framer-motion';
import { Turn as Hamburger } from "hamburger-react";
import { AwesomeButton } from "react-awesome-button";



function Upload() {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [gptResponse, setGptResponse] = useState(null);
  const [showRSAbfrage, setShowRSAbfrage] = useState(false);
  const [showPunkte, setShowPunkte] = useState(false);
  const [showNeinSorry, setShowNeinSorry] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [file, setFile] = useState(null); // State to hold the uploaded file

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
  };

  useEffect(() => {
    sendUserData("Upload");
  }, []);

  const parseGptResponse = (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");
    const data = {};
    lines.forEach((line) => {
      const [key, value] = line.split(":").map((part) => part.trim());
      if (key && value) {
        data[key.replace(/ /g, "").toLowerCase()] = value;
      }
    });

    const neuePunkteLine = data.neuepunkte || "";
    const punkteValue = data.punkte || "0";

    setShowRSAbfrage(neuePunkteLine.includes("SECHSIG PLUS"));
    setShowPunkte(
      punkteValue !== "0" && !neuePunkteLine.includes("SECHSIG PLUS")
    );
    setShowNeinSorry(
      punkteValue === "0" && !neuePunkteLine.includes("SECHSIG PLUS")
    );

    return data;
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFileUploaded(true);
    acceptedFiles.forEach((file) => {
      console.log("File type:", file.type); // Debug log
      
      const formData = new FormData();
      formData.append("file", file);

      fetch("/api/upload", { 
        method: "POST", 
        body: formData 
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          setFileUploaded(false);
          console.log("Server response:", result); // Debug log
          
          if (!result.responseText) {
            throw new Error("No response text received from server");
          }
          
          const responseText = result.responseText;
          if (responseText.toLowerCase().includes("error")) {
            // Handle error response from GPT
            console.error("GPT Error:", responseText);
            // Optionally show error to user
            return;
          }
          
          const parsedResponse = parseGptResponse(responseText);
          setGptResponse(parsedResponse);
          setFile(file);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setFileUploaded(false);
          // Optionally show error to user
          alert("Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.");
        });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    }
  });

  return (
    <>
      <div className="p-2 flex flex-col min-h-[100dvh] max-sm:min-h-[84dvh]  ">
         {/* START mobile menu */}
         <div className="flex flex-row px-4 justify-start gap-6 onlyMobile ">
          <img
            src={logo}
            alt=""
            className="h-[2.2rem] mx-auto flex-grow mt-2"
          />
          <div className="relative z-50">
            <Hamburger
              toggled={isMobileMenuOpen}
              toggle={setIsMobileMenuOpen}
              color="#000000"
              size={20}
            />
          </div>
        </div>
        {/* END mobile menu */}
        <img src={logo} alt="" className="h-[2rem] mx-auto  mt-6  max-sm:hidden" />

        <div className=" flex flex-row justify-center gap-8 mx-auto text-sm  mt-6 max-sm:max-w-[95vw] max-sm:text-[.5rem] MobileHide max-sm:hidden">
          <Link to="/Bussgeldkatalog">
            <p
              className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out "
            >
              Bussgeldkatalog
            </p>
          </Link>
          <Link to="/Impressum">
            <p
              className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out "
            >
              IMPRESSUM
            </p>
          </Link>
          <Link to="/Datenschutz">
            <p
              className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out"
            >
              DATENSCHUTZ
            </p>
          </Link>
          <Link to="/AGB">
            <p
              className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer  underline-offset-8
          hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out"
            >
              AGB
            </p>
          </Link>
        </div>
        {!fileUploaded && !gptResponse && !termsAccepted && (
          <>
            <p className="px-[20%] font-para text-slate-700 text-center text-[.75rem] mt-12 max-sm:px-2">
              {" "}
              Bevor wir beginnen, akzeptieren Sie <br></br> bitte unsere <nbsp></nbsp>
              <Link to="/AGB" className="font-bold underline">
                Allgemeinen Geschäftsbedingungen
              </Link>
              .
            </p>
            <div className="mt-6 flex justify-center">
           
            <button
                onClick={handleAcceptTerms}
                className="bg-blue-500 text-white px-6 py-2 rounded font-para text-xl hover:bg-blue-600 transition-colors"
              >
                AKZEPTIEREN
              </button>
            </div>
          </>
        )}
        {termsAccepted && (
          <>
            <img
              src={uploadWhite}
              alt="Upload"
              className="h-[5rem] mx-auto mt-4 "
            />
            <h1 className="text-slate-700 font-black mx-auto text-center text-[1.5rem] font-para mt-2 uppercase">
              Foto Upload
            </h1>
            <p className="px-[20%] font-para text-slate-700 text-center text-[.75rem] mt-0">
              Nehmen Sie ein Bild von Ihrem Bußgeldbescheid und <br></br> laden
              Sie es hoch. Es wird nur die erste Seite benötigt.
            </p>
          </>
        )}
        {termsAccepted && !fileUploaded && !gptResponse && (
          <div
            {...getRootProps()}
            className="shadow-xl w-fit rounded-xl p-[.3rem] border-2 border-slate-100 mx-auto mt-12 hover:shadow-blue-200 hover:shadow-xl hover:scale-[101%] duration-300 ease-in-out cursor-pointer"
          >
            <input {...getInputProps()} />
            <img
              src={UploadImage}
              className=" h-[10rem] max-sm:h-[8rem]"
              alt=""
            />
          </div>
        )}
        {fileUploaded && (
          <div>
            <div className="justify-center mt-[5rem]">
              <svg
                class="ip"
                viewBox="0 0 256 128"
                width="256px"
                height="128px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stop-color="#07E2F5" />
                    <stop offset="33%" stop-color="#2BBAF4" />
                    <stop offset="67%" stop-color="#2490DC" />
                    <stop offset="100%" stop-color="#0071BC" />
                  </linearGradient>
                  <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                    <stop offset="0%" stop-color="#0071BC" />
                    <stop offset="33%" stop-color="#2490DC" />
                    <stop offset="67%" stop-color="#2BBAF4" />
                    <stop offset="100%" stop-color="#07E2F5" />
                  </linearGradient>
                </defs>
                <g fill="none" stroke-linecap="round" stroke-width="16">
                  <g class="ip__track" stroke="#ddd">
                    <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
                    <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
                  </g>
                  <g stroke-dasharray="180 656">
                    <path
                      class="ip__worm1"
                      stroke="url(#grad1)"
                      stroke-dashoffset="0"
                      d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
                    />
                    <path
                      class="ip__worm2"
                      stroke="url(#grad2)"
                      stroke-dashoffset="358"
                      d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <p
              id="loading-text"
              className="font-para text-[.7rem] text-slate-500"
            >
              Analysiere Bußgeldbescheid<span>.</span>
              <span>.</span>
              <span>.</span>
            </p>
          </div>
        )}
        {gptResponse && (
          <>
            <div className="pl-12 pr-4 max-sm:pl-8 py-4 w-[30rem] max-w-[900px] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw]">
              <img
                src={analysis}
                alt="Analysis Icon. Magnifying glass over a data graph."
                className="mx-auto h-12"
              />
              <h1 className="font-para font-black text-center mx-auto ">
                Analyse
              </h1>

              <h2 className="font-header font-black text-secondary text-[.7rem] tracking-wider mt-12 uppercase leading-[1.2rem]">
                Ausstellende Behörde:
              </h2>
              <p className="text-[24px] leading-[24px] max-sm:text-[17px] font-output ">
                {gptResponse["ausstellendebehörde"] || "Nicht verfügbar"}
              </p>

              <h2 className="font-header font-black text-secondary text-[.7rem] tracking-wider mt-8  leading-[1.2rem]">
                VERSTOß:
              </h2>
              <p className="text-[.8rem] leading-[1rem] font-output ">
                {gptResponse["verstoß"] || "Nicht verfügbar"}
              </p>

              <h2 className="font-header font-black text-secondary text-[.7rem] tracking-wider mt-8  leading-[1.2rem]">
                BUßGELD:
              </h2>
              <p className="text-[24px] leading-[24px] max-sm:text-[17px] font-output">
                {gptResponse["bußgeld"] || "Nicht verfügbar"}
              </p>
              <p className="text-[16px] leading-[24px] max-sm:text-[12px] font-output max-sm:-mt-2">
                {gptResponse["bearbeitungsgebühr"] || "Nicht verfügbar"}
              </p>

              <h2 className="font-header font-black text-secondary text-[.7rem] tracking-wider mt-8 uppercase leading-[1.2rem]">
                Punkte:
              </h2>
              <p className="text-[24px] leading-[24px] max-sm:text-[17px] font-output">
                {gptResponse["punkte"] || "Nicht verfügbar"}
              </p>

              <h2 className="font-header font-black text-secondary text-[.7rem] tracking-wider mt-8 uppercase leading-[1.2rem]">
                Fahrverbot:
              </h2>
              <p className="text-[24px] leading-[24px] max-sm:text-[17px] font-output mb-12">
                {gptResponse["fahrverbot"] || "Nicht verfügbar"}
              </p>
            </div>

            {showRSAbfrage && <RSAbfrage data={gptResponse} file={file} />}
            {showPunkte && <Punkte />}
            {showNeinSorry && <NeinSorry />}
          </>
        )}
      </div>
      
      

      {/* FOOTER */}
      <div className="flex flex-row w-full h-fit home-bg justify-center gap-4 max-sm:flex-col-reverse pt-8 border-t-[1rem] border-slate-700 ">
        <div className="w-[30%] h-[15rem]  max-sm:hidden ">
          <h2 className="text-white font-header font-black text-[2rem]">
            MENU
          </h2>
          <Link to="/Bussgeldkatalog">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
            >
              Bußgeldkatalog
            </p>
          </Link>
          <Link to="/Impressum">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out "
            >
              IMPRESSUM
            </p>
          </Link>
          <Link to="/Datenschutz">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
            >
              DATENSCHUTZ
            </p>
          </Link>
          <Link to="/AGB">
            <p
              className="font-para text-white tracking-widest uppercase cursor-pointer underline-offset-8
          hover:decoration-white decoration-transparent underline duration-300 ease-in-out"
            >
              AGB
            </p>
          </Link>
        </div>
        <div className="w-[30%] h-[15rem] max-sm:w-fit max-sm:text-center max-sm:justify-center max-sm:mx-auto">
          <h2 className="text-white font-header font-black text-[2rem] uppercase">
            Hauptsitz
          </h2>
          <p className="font-para text-white   text-[.8rem] max-sm:text-center max-sm:mx-auto">
            Kalteiche-Ring 46<br></br>
            35708 Haiger<br></br>
            Germany<br></br>
            Mo-Do: 09:00 - 17:00<br></br>
            Fr: 09:00 - 15:00<br></br>
            <br></br>
            <span className="flex flex-row gap-2">
              <img src={phone} alt="Phone Icon." className="h-3 mt-1" /> +49
              2736 2009650
            </span>
            <span className="flex flex-row gap-2">
              <img src={email} alt="Phone Icon." className="h-3 mt-1" />{" "}
              service@JuraSolutions.de
            </span>
          </p>
        </div>
        <div className="w-[30%] h-[15rem]   content-center justify-center  max-sm:w-fit max-sm:mx-auto max-sm:h-fit max-sm:mt-[1rem]">
          {" "}
          <img src={JSOnly} alt="JuraSolutions Logo." className="h-[8rem] mx-auto" />
          <p className="font-para uppercase text-white tracking-widest mx-auto mt-2 text-center text-[10px] font-bold max-sm:text-[.5rem]">
          JuraSolutions Rechtsanwaltsgesellschaft mbH
        </p>
        </div>
      </div>
      {isMobileMenuOpen && (
  <motion.div
    className="fixed top-0 right-0 h-full bg-white z-10 border-l-8 border-slate-700"
    initial={{ x: '100%' }} // Start from the right
    animate={{ x: 0 }} // End at the original position
    exit={{ x: '100%' }} // Exit to the right
    transition={{ type: 'tween', duration: 0.3 }} // Customize the transition
  >
    <div className="flex flex-col items-start justify-start p-6 space-y-4 text-slate-700">
      <h2 className="text-slate-700 font-header font-black text-[2rem] mt-6">
            MENU
          </h2>
            <Link to="/Bussgeldkatalog" className="w-full font-para uppercase">
              Bußgeldkatalog
            </Link>
            <Link to="/Impressum" className="w-full font-para uppercase">
              IMPRESSUM
            </Link>
            <Link to="/Datenschutz" className="w-full font-para uppercase">
              DATENSCHUTZ
            </Link>
            <Link to="/AGB" className="w-full font-para uppercase">
              AGB
            </Link>
    </div>
  </motion.div>
)}
    </>
  );
}

export default Upload;
