import React, { useEffect } from 'react';
import {sendUserData} from "../utils/tracking.js"



function Success() {
  useEffect(() => {
    sendUserData('Erfolg ohne VSN'); 
  }, []);
  
        return (
          <div className="px-12 py-4 w-[30rem] text-xl h-fit rounded-xl shadow-xl grid mx-auto border-2 border-slate-200 mt-12 max-sm:max-w-[95vw] max-sm:px-2">
            <h2 className='font-header text-secondary font-black text-center'>ERFOLG</h2>
            <p className='font-para text-primary mt-4 text-[16px] px-8 leading-[24px]'>
       
             <span className='font-bold'>Das war es schon. Vielen Dank für Ihr Vertrauen</span> <br></br><br></br>In einigen Momenten erhalten Sie eine Auftragsbestätigung auf der von Ihenen angegebenen E- Mail
Adresse.<br></br><br></br>Wir werden uns bei Ihnen melden, um die Versucherungsscheinnummer zu erfragen. 

             
            </p>
            <p className='text-[.6rem] text-center font-para text-slate-400 mt-4 mb-4'>Sie können dieses Fenster nun schließen.</p>
           
          </div>
        );
   

  
}

export default Success;