import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {sendUserData} from "../utils/tracking.js"


function AGB() {
  useEffect(() => {
    sendUserData('AGB'); 
  }, []);
  return (
    <div className="prose prose-lg mx-auto p-6 lg:pr-[30%] lg:pl-[20%] font-para text-[15px] blocksatz">
      <div className="flex flex-row justify-center gap-8 mx-auto text-sm mt-6 max-sm:max-w-[95vw] max-sm:text-[.5rem] MobileHide">
        <Link to='/'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            HOME
          </p>
        </Link>
        <Link to='/Datenschutz'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            DATENSCHUTZ
          </p>
        </Link>
        <Link to='/Impressum'>
          <p className="font-para font-bold text-slate-700 tracking-widest uppercase cursor-pointer underline-offset-8 hover:decoration-slate-700 decoration-transparent underline duration-300 ease-in-out">
            Impressum
          </p>
        </Link>
      </div>
      <h1 className="mt-10 font-header font-black uppercase text-secondary text-3xl">
        Allgemeine Geschäftsbedingungen (AGB)
      </h1>
      <h2 className="mt-6 mb-4 font-bold text-lg">
        der JuraSolutions Rechtsanwaltsgesellschaft mbH
      </h2>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 1 Vertragsgegenstand, Vertragsschluss
      </p>
      <p>
        Mit Absenden des Eingabeformulars und der Übersendung der notwendigen
        Vollmachten übersendet der Einsendende ein verbindliches Angebot für einen
        entgeltlichen Rechtsanwaltsvertrag (Geschäftsbesorgungsvertrag in Gestalt eines
        Dienstvertrages gem. § 675 BGB). Dieser kommt mit einer ausdrücklichen Erklärung der
        JuraSolutions Rechtsanwaltsgesellschaft mbH an den Einsendenden (im Folgenden:
        Mandant) zustande (Übersendung einer Bestätigung der Aktenanlage, sowie des
        Aktenzeichens per E-Mail).
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 2 Leistungen und Obliegenheiten der JuraSolutions Rechtsanwaltsgesellschaft mbH
      </p>
      <p>
        Die JuraSolutions Rechtsanwaltsgesellschaft mbH übernimmt das Mandat. Nach
        Absprache mit dem Mandanten wird dieses außergerichtlich und/oder gerichtlich
        geführt.
        Für die Durchsetzung der Ansprüche des Mandanten werden die notwendigen
        Informationen von der Gegenseite eingeholt.
        Für einen positiven Ausgang des Verfahrens übernimmt die JuraSolutions
        Rechtsanwaltsgesellschaft mbH keine Gewähr.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 3 Leistungen und Obliegenheiten des Mandanten
      </p>
      <p>
        Der Mandant bevollmächtigt die JuraSolutions Rechtsanwaltsgesellschaft mbH, seinen
        Anspruch in seinem Namen durchzusetzen.
        Er hat der JuraSolutions Rechtsanwaltsgesellschaft mbH alle notwendigen
        Informationen zur Verfügung zu stellen. Er hat den tatsächlichen Sachverhalt
        mitzuteilen und alle ihm verfügbaren Dokumente und Nachweise einzureichen.
        Werden ihm im Laufe des Verfahrens weitere Informationen bekannt, sind diese der
        JuraSolutions Rechtsanwaltsgesellschaft mbH unverzüglich mitzuteilen.
        Der Mandant ist verpflichtet, der JuraSolutions Rechtsanwaltsgesellschaft mbH
        unverzüglich mitzuteilen, wenn er Leistungen der Gegenseite oder einer anderen Stelle
        erhält, die seine Forderung (teilweise) begleichen sollen. Er ist ferner verpflichtet, die
        JuraSolutions Rechtsanwaltsgesellschaft mbH über jede Kontaktaufnahme durch die
        Gegenseite zu unterrichten.
        Der Mandant bestätigt, dass er zur Durchsetzung der Forderung nur die JuraSolutions
        Rechtsanwaltsgesellschaft mbH bevollmächtigt hat.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 4 Vergütung, Abrechnung, Auszahlung
      </p>
      <p>
        Der Mandant trägt die für das Verfahren entstandenen Kosten der JuraSolutions
        Rechtsanwaltsgesellschaft mbH nach dem Rechtsanwaltsvergütungsgesetz. Die
        Jurasolutions Rechtsanwalts GmbH ist berechtigt im Rahmen der gesetzlichen
        Vorschriften, Vorschüsse auf Honorare und Auslagen zu erlangen Nach der
        Beendigung des Verfahrens, erhält der Mandant Endabrechnung. Überzahlungen
        oder vereinnahmte Kostenerstattungen der Gegensete werden an den Mandaten
        ausgekehrt, sofern dieser die Zahlungen tatsächlich geleistet hat.
        Der Mandant ist im Fall eines von ihm gewünschten Gerichtsprozesses verpflichtet, die
        durch das Gericht geforderten Vorschüsse zu zahlen.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 5 Haftung
      </p>
      <p>
        Die Haftung der JuraSolutions Rechtsanwaltsgesellschaft mbH ist nach § 52 Abs. 1 Nr.
        2 BRAO beschränkt.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 6 Dauer des Vertrags, Kündigung
      </p>
      <p>
        Der Rechtsanwaltsvertrag endet, wenn der Anspruch des Mandanten nach seinen
        Wünschen durchgesetzt wurde oder das Verfahren rechtskräftig beendet wurde. Er
        endet ferner, wenn die JuraSolutions Rechtsanwaltsgesellschaft mbH nach
        ausführlicher Prüfung der Rechtslage festgestellt hat, dass die (weitere) Durchsetzung
        des Anspruchs aussichtslos ist und sie den Mandanten darüber ausführlich informiert
        hat.
        Darüber hinaus kann das Vertragsverhältnis sowohl durch den Mandanten als auch
        die JuraSolutions Rechtsanwaltsgesellschaft mbH jederzeit gekündigt werden. Der
        Mandant hat im Falle der Kündigung die bis dahin angefallenen Kosten der
        JuraSolutions Rechtsanwaltsgesellschaft mbH zu tragen.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 7 Streitbeilegung, Schlussbestimmungen
      </p>
      <p>
        In Fall einer vermögensrechtlichen Streitigkeit aus dem Mandatsverhältnis erklärt sich
        die JuraSolutions Rechtsanwaltsgesellschaft mbH nicht bereit, an einem
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        Das Recht der Bundesrepublik Deutschland findet unter Ausschluss des UN-Kaufrechts
        Anwendung. Ist der Mandant Verbraucher und hat er zum Zeitpunkt der Bestellung der
        Dienstleistung seinen gewöhnlichen Aufenthalt in einem anderen Land, sind zusätzlich
        die zwingenden Rechtsvorschriften dieses Landes anwendbar.
        Ist der Mandant Kaufmann und hat seinen Sitz zum Zeitpunkt der Bestellung der
        Dienstleistung in Deutschland, ist der ausschließliche Gerichtsstand Düsseldorf. Im
        Übrigen gelten die gesetzlichen Bestimmungen über die örtliche und internationale
        Zuständigkeit.
      </p>
      <p className='font-black text-[18px] mb-1 mt-4'>
        § 8 Allgemeine Hinweise
      </p>
      <p>
        1. Berufshaftpflichtversicherung
      </p>
      <p>
        R+V Allgemeine Versicherung AG
        Raiffeisenplatz 1
        65189 Wiesbaden
        www.ruv.de
        Tel: 0800 533- 1116
      </p>
      <p className="mt-2">
        2. Zuständige Rechtsanwaltskammer
      </p>
      <p>
        Rechtsanwaltskammer Frankfurt am Main<br></br>
        Bockenheimer Anlage 36<br></br>
        60322 Frankfurt am Main<br></br>
        Telefon: +49 69 170098 01<br></br>
        Telefax: +49 69 170098 51<br></br>
        E-Mail: info@rak-ffm.de<br></br>
      </p>
      <p className="mt-2">
        3. Maßgebliche berufsrechtliche Regelungen
      </p>
      <p>
        Für unsere Tätigkeit gelten die nachfolgend genannten berufsrechtlichen
        Regelungen:
        Bundesrechtsanwaltsordnung (BRAO)
        Berufsordnung für Rechtsanwälte (BORA)
        Fachanwaltsordnung (FAO)
        Rechtsanwaltsvergütungsgesetze (RVG)
        Darüber hinaus gelten für den internationalen Rechtsverkehr die Berufsregeln der
        Rechtsanwälte der Europäischen Gemeinschaft (CCBE) sowie das Gesetz für die
        Tätigkeit europäischer Rechtsanwälte in Deutschland (EuRAG).
      </p>
      <p className="mb-12">
        (Stand: April 2024)
      </p>
    </div>
  );
}

export default AGB;